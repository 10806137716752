import { TSelectOption } from "~/components/atoms/form/SelectField";
import { UserStatus } from "~/models/userStatus";

export interface UserAgreement {
  defaultArticleEvaluationBonus: ArticleEvaluationBonus | null;
  defaultCharacterUnitPrice: number | null;
  defaultMaxCharacterForReward: number | null;
  id: UserStatus["teamId"];
  userId: string | null;
}

export interface ArticleEvaluationBonus {
  pricePerEvaluation: number;
}

export const DEFAULT_USER_AGREEMENT_TEAM_ID = "kikushiru";

export function findUserAgreementForUser({
  userAgreements,
  userId,
  userTeamId,
}: {
  userAgreements: UserAgreement[] | undefined;
  userId: string | undefined;
  userTeamId: string | undefined;
}) {
  return userAgreements?.find(
    (u) => u.id === userTeamId && u.userId === userId
  );
}

export function isValidArticleEvaluationBonus(
  articleEvaluationBonus: any
): articleEvaluationBonus is ArticleEvaluationBonus {
  return (
    typeof articleEvaluationBonus?.pricePerEvaluation === "number" &&
    !isNaN(articleEvaluationBonus?.pricePerEvaluation)
  );
}

export function generateArticleEvaluationBonusOptions(): TSelectOption[] {
  return [
    { text: "なし", value: "" },
    { text: "あり", value: "true" },
  ];
}
