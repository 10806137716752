import { doc, getDoc } from "firebase/firestore";
import { userConverter } from "~/iterators/firebaseConverter/user";
import { firestore } from "~/iterators/firestore/firestore";
import { retrieveUsers } from "~/iterators/firestore/users/retrieveUsers";
import { FIRESTORE_COLLECTION_NAME_USER, User } from "~/models/user";

export async function retrieveUser({
  useCache = true,
  userId,
}: {
  useCache?: boolean;
  userId: string;
}): Promise<User | undefined> {
  if (!userId) {
    return undefined;
  }

  if (useCache) {
    const users = await retrieveUsers({ userIds: [userId] });
    return users?.length ? users[0] : undefined;
  }

  const userDoc = await getDoc(
    doc(firestore, FIRESTORE_COLLECTION_NAME_USER, userId).withConverter(
      userConverter
    )
  );

  return userDoc.exists() ? userDoc.data() : undefined;
}
