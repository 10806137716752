import React, { VFC, ReactElement } from "react";
import styled, { css } from "styled-components";

interface Props {
  children: ReactElement;
  leftSidebar?: React.ReactNode;
  rightSidebar?: React.ReactNode;
}

const SidebarLayout: VFC<Props> = ({ children, leftSidebar, rightSidebar }) => {
  return (
    <Main className="main" hasRightSidebar={!!rightSidebar}>
      {leftSidebar && <LeftSidebar>{leftSidebar}</LeftSidebar>}
      <div>{children}</div>
      {rightSidebar && <RightSidebar>{rightSidebar}</RightSidebar>}
    </Main>
  );
};

const Main = styled.main<{ hasRightSidebar: boolean }>`
  ${({ hasRightSidebar }) => {
    if (!hasRightSidebar) {
      return;
    }

    if (hasRightSidebar) {
      return css`
        justify-content: center;
        position: relative;
        width: 100%;
        margin: 0 auto;
        max-width: 1088px;
        @media only screen and (min-width: 992px) {
          display: grid;
          gap: 24px;
          grid-template-columns: minmax(auto, 720px) 240px;
        }
        @media only screen and (min-width: 1080px) {
          display: grid;
          gap: 40px;
          grid-template-columns: minmax(auto, 720px) 280px;
        }
      `;
    }
  }}
`;

const RightSidebar = styled.aside`
  display: none;

  @media only screen and (min-width: 992px) {
    display: block;
  }
`;

const LeftSidebar = styled.aside`
  position: absolute;
  left: -80px;
  width: 80px;
  height: 100%;

  @media only screen and (max-width: 1240px) {
    display: none;
  }
`;

export default SidebarLayout;
