import { SITE_NAME } from "lib/constants";
import NextHeadSeo from "next-head-seo";
import Head from "next/head";
import React, { VFC } from "react";
import { getServerBaseUrl } from "~/lib/utils/urlHelper";
import { Project } from "~/models/project";
interface Props {
  description?: string;
  isNoIndexPage?: boolean;
  project?: Project;
  title: string;
}

export const MetaTag: VFC<Props> = ({
  description,
  isNoIndexPage = false,
  project,
  title,
}) => {
  return (
    <>
      <NextHeadSeo
        description={description}
        robots={isNoIndexPage ? "noindex, nofollow" : "index, follow"}
        title={title}
      />
      <Head>
        <link
          rel="shortcut icon"
          href={project?.faviconPath || "/favicon.ico"}
        />
        <link rel="manifest" href="/manifest.json" />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href={
            project?.appleIcon57Path || "/img/site-icon/apple-icon-57x57.png"
          }
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href={
            project?.appleIcon60Path || "/img/site-icon/apple-icon-60x60.png"
          }
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href={
            project?.appleIcon72Path || "/img/site-icon/apple-icon-72x72.png"
          }
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href={
            project?.appleIcon76Path || "/img/site-icon/apple-icon-76x76.png"
          }
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href={
            project?.appleIcon114Path || "/img/site-icon/apple-icon-114x114.png"
          }
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href={
            project?.appleIcon120Path || "/img/site-icon/apple-icon-120x120.png"
          }
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href={
            project?.appleIcon144Path || "/img/site-icon/apple-icon-144x144.png"
          }
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href={
            project?.appleIcon152Path || "/img/site-icon/apple-icon-152x152.png"
          }
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={
            project?.appleIcon180Path || "/img/site-icon/apple-icon-180x180.png"
          }
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={
            project?.androidIcon192Path ||
            "/img/site-icon/android-icon-192x192.png"
          }
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={project?.favicon32Path || "/img/site-icon/favicon-32x32.png"}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href={project?.favicon96Path || "/img/site-icon/favicon-96x96.png"}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={project?.favicon16Path || "/img/site-icon/favicon-16x16.png"}
        />
        <meta
          name="msapplication-TileImage"
          content={
            project?.msIcon144Path || "/img/site-icon/ms-icon-144x144.png"
          }
        />
        <meta name="msapplication-TileColor" content="#98CB51" />
        <meta name="theme-color" content="#98CB51" />
      </Head>
    </>
  );
};

export const DefaultMetaTag: VFC = () => {
  // TODO: Add Twitter Information
  return (
    <>
      <NextHeadSeo
        customMetaTags={[
          {
            content: "ja_JP",
            property: "og:locale",
          },
        ]}
        description="「聞いて知れる」、Q&Aサイトです。どんな質問にも答えます！"
        title={SITE_NAME}
        canonical={getServerBaseUrl()}
        og={{
          siteName: SITE_NAME,
          type: "website",
          url: getServerBaseUrl(),
        }}
      />
    </>
  );
};
