import "lib/firebase/firebase";
import { getFirestore, Firestore } from "firebase/firestore";

let firestore: Firestore;

// @ts-ignore
if (!firestore) {
  firestore = getFirestore();
}

export { firestore };
