import {
  collection,
  getDocs,
  query,
  QueryConstraint,
  where,
} from "firebase/firestore";
import {
  convertToUsers,
  userConverter,
} from "~/iterators/firebaseConverter/user";
import { firestore } from "~/iterators/firestore/firestore";
import { errorWithoutThrowInProduction } from "~/lib/logging/logHelper";
import { getNewestUpdatedUnixTime } from "~/lib/utils/objectHelper";
import { FIRESTORE_COLLECTION_NAME_USER, User } from "~/models/user";

// get user data from cached json
export function retrieveCachedUsers(): User[] | undefined {
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const seedUsers = require("faqSrc/data/seed/users.json") as User[];
    return seedUsers;
  } catch (e) {
    errorWithoutThrowInProduction({
      errorInfo: e?.stack,
      errorMessage: e?.message,
    });
  }

  return undefined;
}

export async function retrieveUsersFromLatestCache({
  queryConstraints,
  seedUsers,
}: {
  queryConstraints: QueryConstraint[];
  seedUsers?: User[];
}): Promise<User[]> {
  const collectionRef = collection(
    firestore,
    FIRESTORE_COLLECTION_NAME_USER
  ).withConverter(userConverter);
  if (!seedUsers?.length) {
    const usersSnapshot = await getDocs(
      query(collectionRef, ...queryConstraints)
    );
    return convertToUsers({ usersSnapshot });
  }

  // get updated data from firebase
  const usersSnapshot = await getDocs(
    query(
      collectionRef,
      ...queryConstraints,
      where("updatedUnixTime", ">", getNewestUpdatedUnixTime(seedUsers))
    )
  );
  const updatedUsers = convertToUsers({ usersSnapshot });

  if (!updatedUsers?.length) {
    return [...seedUsers];
  }

  // replace existing data with the newest
  const updatedIds = updatedUsers.map((u) => u.id);
  return [
    ...seedUsers.filter((u) => !updatedIds.includes(u.id)),
    ...updatedUsers,
  ];
}
