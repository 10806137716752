import { Translate } from "next-translate";
import * as zod from "zod";
import { TSelectOption } from "~/components/atoms/form/SelectField";
import { optionalUrlField } from "~/models/fields";

export const FIRESTORE_COLLECTION_NAME_USER = "users";
export const FIRESTORE_COLLECTION_NAME_USER_AGREEMENT = "agreements";
export const FIRESTORE_COLLECTION_NAME_USER_DAILY_QA_SUMMARY =
  "dailyQaSummaries";
export const FIRESTORE_COLLECTION_NAME_USER_PRIVATE = "userPrivate";
export const FIRESTORE_COLLECTION_NAME_USER_MANAGEMENT = "managements";
export const DEFAULT_AUTHOR_ID =
  process.env.NODE_ENV === "production"
    ? "oEDuCNTn9WPFVNjwdrx3Pwevi4H3"
    : "O8mviRhA8rZOj3lOkyvkfARwUZy1";

export interface User {
  articleCVAverageLast30Days?: number;
  articleCount?: number;
  articleFbHelpfulCount?: number;
  articleFbHelpfulAverageLast30Days?: number;
  articleFbHelpfulCountLast30Days?: number;
  articlePVAverageLast30Days?: number;
  articlePageViewCount?: number;
  articlePageViewCountLast30Days?: number;
  articleTextCountAverage?: number;
  blogUrl?: string;
  coverImageUrl?: string | null;
  createdUnixTime: number;
  description?: string;
  id: string;
  imageUrl?: string | null;
  instagramUrl?: string;
  jobTitle?: string;
  name: string;
  portfolioUrl1?: string;
  portfolioUrl2?: string;
  specialtyIds?: string[];
  supportAdClickCount?: number;
  twitterUrl?: string;
  updatedUnixTime: number;
  visible: boolean;
  youtubeUrl?: string;
}

export interface UserIDictionary {
  [index: string]: User;
}

export const USER_SCHEMA = (t: Translate) =>
  zod.object({
    blogUrl: optionalUrlField(),
    description: zod
      .string()
      .nonempty({
        message: t("form:validation.empty-input", {
          name: t("user:label-description"),
        }),
      })
      .min(100, {
        message: t("form:validation.min-max", { max: 1000, min: 100 }),
      })
      .max(1000, {
        message: t("form:validation.min-max", { max: 1000, min: 100 }),
      }),
    instagramUrl: zod
      .string()
      .optional()
      .refine(
        (url) =>
          !url?.length || socialUrlValidate({ socialName: "instagram", url }),
        {
          message: t("form:validation.invalid", {
            name: t("user:label-instagram-url"),
          }),
        }
      )
      .nullable(),
    jobTitle: zod.string().optional().nullable(),
    name: zod.string().nonempty({
      message: t("form:validation.empty-input", {
        name: t("user:label-user-name"),
      }),
    }),
    portfolioUrl1: optionalUrlField(),
    portfolioUrl2: optionalUrlField(),
    twitterUrl: zod
      .string()
      .optional()
      .refine(
        (url) =>
          !url?.length || socialUrlValidate({ socialName: "twitter", url }),
        {
          message: t("form:validation.invalid", {
            name: t("user:label-twitter-url"),
          }),
        }
      )
      .nullable(),
    youtubeUrl: zod
      .string()
      .optional()
      .refine(
        (url) =>
          !url?.length || socialUrlValidate({ socialName: "youtube", url }),
        {
          message: t("form:validation.invalid", {
            name: t("user:label-youtube-url"),
          }),
        }
      )
      .nullable(),
  });

export function qaCountUserSort(a: User, b: User): number {
  return (b.articleCount || 0) - (a.articleCount || 0);
}

export function newestUserSort(a: User, b: User) {
  return b.createdUnixTime - a.createdUnixTime;
}

export function generateUserOptions(
  users?: User[] | undefined
): TSelectOption[] {
  return users ? users.map((t) => ({ text: t.name, value: t.id })) : [];
}

function socialUrlValidate({
  url,
  socialName,
}: {
  url: string;
  socialName: "instagram" | "twitter" | "youtube";
}): boolean {
  // skip for empty value
  if (!url) {
    return true;
  }

  switch (socialName) {
    case "instagram":
      return !!url.match(
        /^((?:http:\/\/)?|(?:https:\/\/)?)?(?:www\.)?instagram\.com\/.+$/i
      );
    case "twitter":
      return !!url.match(
        /^((?:http:\/\/)?|(?:https:\/\/)?)?(?:www\.)?twitter\.com\/(\w+)$/i
      );
    case "youtube":
      return !!url.match(
        /^((?:http:\/\/)?|(?:https:\/\/)?)?(?:www\.)?youtube\.com\/.+$/i
      );
  }
}

export function toUserIDictionary(
  initialUsers: User[] | undefined,
  currentUser?: User
): UserIDictionary {
  const users: User[] = [];
  if (currentUser?.id) {
    users.push(currentUser);
  }
  if (initialUsers?.length) {
    users.push(...initialUsers);
  }

  if (!users?.length) {
    return {};
  }

  return users?.reduce(
    (arr: UserIDictionary, user: User) => ((arr[user.id] = user), arr),
    {}
  );
}

export function getFullProfileImageUrl(imageUrl: string): string {
  if (!imageUrl || !imageUrl.includes("googleusercontent.com/")) {
    return imageUrl;
  }

  const regex = new RegExp("=s96-c|s96-c/");
  return imageUrl.replace(regex, "");
}
