import { extendTheme } from "@chakra-ui/react";
import {
  breakPoint,
  colorBlack,
  colorImportant,
  colorPrimary,
  colorStatistic,
  colorSupportAd,
} from "~/lib/constants";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Button: ComponentStyleConfig = {
  // The styles all button have in common
  baseStyle: {
    _hover: {
      opacity: 0.8,
    },
    borderRadius: "5px",
  },
  defaultProps: {
    size: "md",
    variant: "solid",
  },
  sizes: {
    sm: {
      px: 4,
    },
    xlg: {
      height: "52px",
      px: "32px",
      py: "10px",
    },
  },
  variants: {
    outline: {
      border: "1px solid",
      borderColor: "primary.500",
      color: "primary.5000",
    },
    solid: {
      bg: "primary.500",
      color: "white",
    },
  },
};

/* eslint-disable sort-keys */
export const chakraTheme = extendTheme({
  breakpoints: { ...breakPoint },
  colors: {
    black: { ...colorBlack },
    important: { ...colorImportant },
    primary: { ...colorPrimary },
    statistic: { ...colorStatistic },
    supportAd: { ...colorSupportAd },
  },
  components: {
    Button,
    Alert: {
      variants: {
        subtle: () => {
          return {
            description: {
              fontSize: "14px",
            },
            container: {
              bg: `black.300`,
              borderRadius: "20px",
              borderTopRadius: "20px",
              borderBottomRadius: "20px",
              border: "none",
              color: `white`,
              display: "inline-grid",
              gridAutoFlow: "column",
              py: 2,
            },
            spinner: {
              color: `white`,
            },
          };
        },
      },
    },
  },
});
