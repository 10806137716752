import Image from "components/atoms/Image";
import { colorPrimary } from "lib/constants";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import React, { VFC, useContext } from "react";
import { slide as SlideMenu } from "react-burger-menu";
import styled, { createGlobalStyle } from "styled-components";
import { isAdminOrOwner, isWriter } from "~/models/userStatus";
import { hideScrollbar } from "~/styles/CommonStyles";
import { pagesPath, staticPath } from "mypage/src/lib/$path";
import { AuthContext } from "mypage/src/lib/auth/AuthProvider";

const slideMenuOpenBodyClassName = "slide-menu-open";

const SideMenu: VFC = () => {
  const { userRole } = useContext(AuthContext);
  const { t } = useTranslation();

  return (
    <>
      <SlideMenu
        right
        pageWrapId="main"
        width={284}
        bodyClassName={slideMenuOpenBodyClassName}
      >
        <Link href={pagesPath.home.$url()} passHref>
          <MenuItem>
            <Image
              src={staticPath.img.icon_mypage_home_green_svg}
              width="20"
              height="20"
              alt="icon home"
            />
            <span>ホーム</span>
          </MenuItem>
        </Link>

        <Link href={pagesPath.analytics.$url()} passHref>
          <MenuItem>
            <Image
              src={staticPath.img.icon_graph_svg}
              width="20"
              height="20"
              alt="icon graph"
            />
            <span>分析情報</span>
          </MenuItem>
        </Link>

        {isAdminOrOwner(userRole) && (
          <>
            <Link href={pagesPath.topics.$url()} passHref>
              <MenuItem>
                <Image
                  src={staticPath.img.icon_topic_svg}
                  width="20"
                  height="20"
                  alt="icon topic"
                />
                <span>{t("mypage:side-menu.topic-edit")}</span>
              </MenuItem>
            </Link>
            <Link href={pagesPath.subTopics.$url()} passHref>
              <MenuItem>
                <Image
                  src={staticPath.img.icon_sub_topic_svg}
                  width="20"
                  height="20"
                  alt="icon sub topic"
                />
                <span>{t("mypage:side-menu.sub-topic-edit")}</span>
              </MenuItem>
            </Link>
          </>
        )}

        {isAdminOrOwner(userRole) && (
          <Link href={pagesPath.specialities.$url()} passHref>
            <MenuItem>
              <Image
                src={staticPath.img.icon_medal_svg}
                width="20"
                height="20"
                alt="icon speciality"
              />
              <span>{t("mypage:side-menu.speciality-edit")}</span>
            </MenuItem>
          </Link>
        )}

        <Link href={pagesPath.user.edit.step1.$url()} passHref>
          <MenuItem>
            <Image
              src={staticPath.img.icon_user_svg}
              width="20"
              height="20"
              alt="icon user"
            />
            <span>{t("mypage:side-menu.profile-edit")}</span>
          </MenuItem>
        </Link>

        {isWriter(userRole) && (
          <Link href={pagesPath.bill.list.$url()} passHref>
            <MenuItem>
              <Image
                src={staticPath.img.icon_invoice_svg}
                width="20"
                height="20"
                alt="icon bill"
              />
              <span>請求書一覧</span>
            </MenuItem>
          </Link>
        )}

        {isWriter(userRole) && (
          <Link
            href={pagesPath.contract.basicBusinessConsignment.$url()}
            passHref
          >
            <MenuItem>
              <Image
                src={staticPath.img.icon_approve_svg}
                width="20"
                height="20"
                alt="icon user"
              />
              <span>{t("mypage:side-menu.business-consignment")}</span>
            </MenuItem>
          </Link>
        )}

        {(userRole === "admin" || userRole === "owner") && (
          <Link href={pagesPath.about_us.edit.$url()} passHref>
            <MenuItem>
              <Image
                src={staticPath.img.icon_visitor_svg}
                width="20"
                height="20"
                alt="icon visitor"
              />
              <span>{t("mypage:side-menu.operator-edit")}</span>
            </MenuItem>
          </Link>
        )}
        {isAdminOrOwner(userRole) && (
          <Link href={pagesPath.bill.$url()} passHref>
            <MenuItem>
              <Image
                src={staticPath.img.icon_invoice_svg}
                width="20"
                height="20"
                alt="icon bill"
              />
              <span>{t("mypage:side-menu.bill-management")}</span>
            </MenuItem>
          </Link>
        )}
        <Link href={pagesPath.user.bill.edit.$url()} passHref>
          <MenuItem>
            <Image
              src={staticPath.img.icon_invoice_svg}
              width="20"
              height="20"
              alt="icon bill"
            />
            <span>{t("mypage:side-menu.bill-info-edit")}</span>
          </MenuItem>
        </Link>
        {isAdminOrOwner(userRole) && (
          <Link href={pagesPath.management.sendMail.$url()} passHref>
            <MenuItem>
              <Image
                src={staticPath.img.icon_mail_green_svg}
                width="20"
                height="20"
                alt="icon email"
              />
              <span>{t("mypage:side-menu.send-email")}</span>
            </MenuItem>
          </Link>
        )}
      </SlideMenu>
      <SlideMenuCSS />
    </>
  );
};

const SlideMenuCSS = createGlobalStyle`
  body.${slideMenuOpenBodyClassName} {
    overflow: hidden;
  }
  .bm-burger-button {
    height: 24px;
    position: relative;
    width: 24px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: ${colorPrimary[500]};
    border-radius: 6px;
    height: 5px!important;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    top: 40px !important;
    right: 40px !important;

    > span {
      top: 10px !important;
      right: 26px !important;
    }
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: ${colorPrimary[500]};
    height: 5px !important;
    width: 28px !important;
    border-radius: 4px;
  }

  /* General sidebar styles */
  .bm-menu-wrap {
    top: 0;
    background: #fff;
    border: 1px solid ${colorPrimary[300]};
  }
  .bm-menu {
    ${hideScrollbar}
    padding: 64px 40px 40px 40px;
    font-size: 18px;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: ${colorPrimary[500]};
    height: auto;
    margin-bottom: 60px; // create space at the bottom in case for scrollable long list
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    left: 0;
    top: 0;
  }
`;

const MenuItem = styled.a`
  display: grid;
  grid-template-columns: 20px auto;
  gap: 12px;
  align-items: center;
  color: ${colorPrimary[500]};
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  margin-top: 40px;

  &:hover {
    opacity: 0.8;
  }
`;

export default SideMenu;
