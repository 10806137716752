import * as auth from "firebase/auth";
import { loginInWithGoogle } from "lib/auth/loginInWithGoogle";
import { errorWithoutThrowInProduction } from "lib/logging/logHelper";
import nookies from "nookies";
import React, {
  FC,
  createContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { retrieveUserStatusWithRetry } from "~/iterators/firestore/userStatus/retrieveUserStatus";
import { UserStatus } from "~/models/userStatus";
import { createOrFindUserService } from "mypage/src/services/users/createOrFindUserService";

type AuthContextProps = {
  concurrentSelfTask: UserStatus["concurrentSelfTask"];
  createTaskOnCompletion: UserStatus["createTaskOnCompletion"];
  firebaseUser: auth.User | null | undefined;
  // when logging in, use this value to know if login is on process
  isFirebaseIdTokenChanging: boolean;
  isFirebaseLoading: boolean;
  loginInWithGoogle: () => void;
  userRole: UserStatus["role"];
  userTeamId: UserStatus["teamId"];
};

const AuthContext = createContext<AuthContextProps>({
  concurrentSelfTask: 0,
  createTaskOnCompletion: true,
  firebaseUser: undefined,
  isFirebaseIdTokenChanging: false,
  isFirebaseLoading: false,
  loginInWithGoogle,
  userRole: "normal",
  userTeamId: null,
});

const cookieOptions = {
  path: "/",
  secure: true,
};

const AuthProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isIdTokenChanging, setIdTokenChanging] = useState<boolean>(false);
  const [firebaseUser, setFirebaseUser] = useState<
    auth.User | null | undefined
  >(undefined);
  const [userStatus, setUserStatus] = useState<UserStatus>();

  const onLogin = useCallback(
    ({
      firebaseUser,
      userStatus,
    }: {
      firebaseUser: auth.User;
      userStatus: UserStatus;
    }) => {
      setFirebaseUser(firebaseUser);
      setUserStatus(userStatus);
    },
    []
  );

  const onLogout = useCallback(() => {
    setFirebaseUser(undefined);
    setUserStatus(undefined);
  }, []);

  useEffect(() => {
    // Adds an observer for changes to the signed-in user's ID token, which includes:
    // - sign-in
    // - sign-out
    // - token refresh
    // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#onidtokenchanged
    const unsubscribe = auth.getAuth().onIdTokenChanged(
      async (firebaseUser) => {
        setIdTokenChanging(true);
        if (!firebaseUser) {
          // clear user token in cookie
          nookies.destroy(null, "token");
          nookies.set(null, "token", "", cookieOptions);
          onLogout();
          setIsLoading(false);
          setIdTokenChanging(false);
          return;
        }

        try {
          const idToken = await firebaseUser.getIdToken();
          // set user token to cookies
          nookies.destroy(null, "token");
          nookies.set(null, "token", idToken, cookieOptions);

          const { isSuccess, user } = await createOrFindUserService({
            email: firebaseUser.email,
            firebaseIdToken: idToken,
            name: firebaseUser.displayName,
            originalImageUrl: firebaseUser.photoURL,
            userId: firebaseUser.uid,
          });
          const firebaseUserLogStr = `firebaseUID: ${firebaseUser.uid}, email: ${firebaseUser.email}, photoURL: ${firebaseUser.photoURL}`;
          if (!isSuccess || !user) {
            throw new Error(
              `Failed to create or find user. ${firebaseUserLogStr}`
            );
          }

          const userStatus = await retrieveUserStatusWithRetry({
            userId: user.id,
          });

          if (!userStatus) {
            throw new Error(
              `UserStatus does not exist. ${firebaseUserLogStr}, user: ${JSON.stringify(
                user
              )}`
            );
          }

          onLogin({
            firebaseUser,
            userStatus,
          });
        } catch (e: any) {
          errorWithoutThrowInProduction({
            errorInfo: { error: e, message: e?.message, stack: e?.stack },
            errorMessage: "Error occurred in onIdTokenChanged handler",
          });
          onLogout();
        } finally {
          setIsLoading(false);
          setIdTokenChanging(false);
        }
      },
      (error) => {
        errorWithoutThrowInProduction({
          errorInfo: { error },
          errorMessage: "firebase.auth.Error in onIdTokenChanged",
        });
      }
    );

    return () => {
      unsubscribe();
    };
  }, [onLogin, onLogout]);

  // force refresh the token every 50 minutes
  useEffect(() => {
    const handle = setInterval(async () => {
      const user = auth.getAuth().currentUser;
      if (user) {
        await user.getIdToken(true);
      }
    }, 50 * 60 * 1000);

    // clean up setInterval
    return () => clearInterval(handle);
  }, []);

  /* Wrapping components in the lower level */
  return (
    <AuthContext.Provider
      value={{
        concurrentSelfTask: userStatus?.concurrentSelfTask ?? 0,
        createTaskOnCompletion: userStatus?.createTaskOnCompletion ?? true,
        firebaseUser,
        isFirebaseIdTokenChanging: isIdTokenChanging,
        isFirebaseLoading: isLoading,
        loginInWithGoogle,
        userRole: userStatus?.role as UserStatus["role"],
        userTeamId: userStatus?.teamId || null,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
