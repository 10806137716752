import { useAmp } from "next/amp";
import NextImage from "next/image";
import React, { FC } from "react";
import styled, { css, CSSProperties } from "styled-components";

interface Props {
  alt: string;
  // using to specific layout for amp-img tag
  ampLayout?:
    | "fill"
    | "fixed"
    | "fixed-height"
    | "flex-item"
    | "intrinsic"
    | "nodisplay"
    | "responsive"
    | "container";
  layout?: "fixed" | "intrinsic" | "responsive";
  // used as default src during lazy load time
  // after lazy loaded defaultSrc will be replaced by src
  defaultSrc?: string;
  disableHover?: boolean;
  // image display property
  display?: CSSProperties["display"];
  // image not implemented lazy load when turn unLazyLoad on
  unLazyLoad?: boolean;
  // height is required in AMP page
  height: number | string;
  src: string;
  // width is required in AMP page
  width: number | string;
  objectFit?: "cover" | "scale-down";
}

// all image will be implemented lazy loading
const Image: FC<Props> = ({
  alt,
  layout = "fixed",
  ampLayout,
  disableHover,
  display,
  unLazyLoad,
  height,
  src,
  width,
  objectFit,
}) => {
  const isAmp = useAmp();

  if (isAmp) {
    return (
      <amp-img
        alt={alt}
        src={src}
        width={width}
        height={height}
        layout={ampLayout}
        class={objectFit}
      />
    );
  }

  return (
    <ImageTag
      isResponsive={layout === "responsive"}
      dataDisplay={display}
      dataDisableHover={disableHover}
    >
      <NextImage
        src={src}
        alt={alt}
        width={width}
        height={height}
        layout={layout}
        loading={unLazyLoad ? "eager" : "lazy"}
        objectFit={objectFit}
        unoptimized={true}
      />
    </ImageTag>
  );
};

const ImageTag = styled.span<{
  dataDisplay?: string;
  isResponsive?: boolean;
  dataDisableHover?: boolean;
}>`
  display: ${(props) => props.dataDisplay || "inherit"};
  :hover {
    opacity: ${(props) => (props.dataDisableHover ? "1" : "0.8")};
  }

  ${(props) => {
    if (props.isResponsive) {
      return css`
        width: 100%;
        height: auto;
      `;
    }
  }};
`;

export default Image;
