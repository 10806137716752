/**
 * Returns a new copy of passed object, excluding keys whose value is undefined.
 *
 * extractNonUndefined({a: 'value', b: undefined}) -> {a: 'value'}
 */
export function extractNonUndefined<T extends Record<string, unknown>>(
  record: T
): T {
  return Object.entries(record).reduce((prev, [k, v]) => {
    if (v === undefined) {
      return prev;
    }
    return {
      ...prev,
      [k]: v,
    };
  }, {}) as T;
}

/**
 * Returns a new copy of passed object, change undefined value to null.
 *
 * convertUndefinedToNull({a: 'value', b: undefined}) -> {a: 'value', b: null}
 */
export function convertUndefinedToNull<T extends Record<string, unknown>>(
  record: T
): T {
  return Object.entries(record).reduce((prev, [k, v]) => {
    return {
      ...prev,
      [k]: v === undefined ? null : v,
    };
  }, {}) as T;
}

export function getNewestUpdatedUnixTime<
  T extends readonly { updatedUnixTime: number }[]
>(items: T): number {
  if (items.length === 0) {
    return 0;
  }
  return Math.max(...items.map((item) => item.updatedUnixTime));
}
