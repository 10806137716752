import { doc, getDoc } from "firebase/firestore";
import { userStatusConverter } from "~/iterators/firebaseConverter/userStatus";
import { firestore } from "~/iterators/firestore/firestore";
import { wait } from "~/lib/utils/requestAndRetry";
import {
  FIRESTORE_COLLECTION_NAME_USER_STATUS,
  UserStatus,
} from "~/models/userStatus";

export async function retrieveUserStatus({
  userId,
}: {
  userId: string;
}): Promise<UserStatus | undefined> {
  const userStatusDoc = await getDoc(
    doc(firestore, FIRESTORE_COLLECTION_NAME_USER_STATUS, userId).withConverter(
      userStatusConverter
    )
  );

  if (userStatusDoc.exists()) {
    return userStatusDoc.data();
  }
}

export async function retrieveUserStatusWithRetry({
  delayTime = 2000,
  maxRetry = 3,
  userId,
}: {
  delayTime?: number;
  maxRetry?: number;
  userId: string;
}): Promise<UserStatus | undefined> {
  for (let retryCount = 0; retryCount < maxRetry; retryCount++) {
    try {
      const userStatus = await retrieveUserStatus({ userId });
      if (!userStatus) {
        throw Error("userStatus not found");
      }
      return userStatus;
    } catch {
      if (retryCount >= 2) {
        return undefined;
      }
      await wait(delayTime);
    }
  }
}
