import { createGlobalStyle } from "styled-components";
import { breakPoint, colorBlack } from "lib/constants";
import {
  contentFullWrap,
  contentWrap,
  hideScrollbar,
  limitedLineContent,
} from "~/styles/CommonStyles";
import { DarkTheme, DefaultTheme } from "~/styles/Theme";

const ResetCss = createGlobalStyle<{ theme: { supportDarkMode?: boolean } }>`
  html,
      body,
      div,
      span,
      applet,
      object,
      iframe,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      blockquote,
      pre,
      a,
      abbr,
      acronym,
      address,
      big,
      cite,
      code,
      del,
      dfn,
      em,
      font,
      img,
      ins,
      kbd,
      q,
      s,
      samp,
      small,
      strike,
      strong,
      sub,
      sup,
      tt,
      var,
      b,
      u,
      i,
      center,
      dl,
      dt,
      dd,
      ol,
      ul,
      li,
      fieldset,
      form,
      label,
      legend,
      table,
      caption,
      tbody,
      tfoot,
      thead,
      tr,
      th,
      td {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        font-size: 100%;
        vertical-align: baseline;
        background: transparent;
        box-sizing: border-box;
      }

      /* disable double-tap to zoom */
      * {
        touch-action: manipulation;
      }

      html {
        scroll-behavior: smooth;
      }
      ol,
      ul {
        list-style: none;
      }
      blockquote,
      q {
        quotes: none;
      }
      blockquote:before,
      blockquote:after,
      q:before,
      q:after {
        content: none;
      }

      /* remember to define focus styles! */
      :focus {
        outline: 0;
      }

      /* tables still need 'cellspacing="0"' in the markup */
      table {
        border-collapse: collapse;
        border-spacing: 0;
      }

      a {
        text-decoration: none;
      }

      /**** Common Design Start ****/
      body {
        ${DefaultTheme};
        ${(props) => {
          if (props.theme.supportDarkMode) {
            return DarkTheme;
          }
        }}
       
        color: var(--body-clr);
        background-color : var(--body-bg-clr);

        /** Font **/
        font-family: -apple-system, system-ui, BlinkMacSystemFont, system,
          "Segoe UI", "Hiragino Sans", "Hiragino Kaku Gothic ProN",
          "ヒラギノ角ゴ ProN W3", メイリオ, Meiryo, "Noto Sans", noto,
          "Fira Sans", "Droid Sans", "Droid Sans Fallback", "Roboto",
          "Cantarell", "Ubuntu", "Helvetica Neue", Arial, sans-serif,
          Apple Color Emoji, Noto Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
          emoji;

        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.03em;
        line-height: 1.5;
        text-size-adjust: 100%;
      }
      /** Get full height except header footer **/
      .main {
        min-height: calc(72vh);
      }
      .content-wrap {
        ${contentWrap};
      }
      .content-full-wrap {
        ${contentFullWrap};
      }
      .content-wrap-lg {
        padding-left: 16px;
        padding-right: 16px;
        max-width: 1073px;  // width without padding is 1040px
        margin-left: auto;
        margin-right: auto;

        @media only screen and (min-width: ${breakPoint["md"]}) {
          padding-left: 24px;
          padding-right: 24px;
          max-width: 1088px;  // width without padding is 1040px
        }
      }
      
      .limited-line--1, .limited-line--2 {
        ${limitedLineContent};
      }
      .limited-line--1 {
        -webkit-line-clamp: 1;
      }
      .limited-line--2 {
        -webkit-line-clamp: 2;
      }
      .hide-scrollbar {
        ${hideScrollbar};
      }
      .twemoji {
        display: inline-block;
        height: 1em;
        width: 1em;
        margin: 0 0.05em 0 0.1em;
        vertical-align: -0.15em;
      }
      .visible-pc {
        display: none;
      }
      .visible-sp {
        display: none;
      }
      @media (min-width: 681px) {
        .visible-pc {
          display: block;
        }
      }
      @media (max-width: 680px) {
        .visible-sp {
          display: block;
        }
      }

      /* placeholder style */
      .light-placeholder{
        &::-webkit-input-placeholder {
          /* Edge */
          font-weight: 400;
          color: ${colorBlack[400]};
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          font-weight: 400;
          color: ${colorBlack[400]};
        }

        &::placeholder {
          font-weight: 400;
          color: ${colorBlack[400]};
        }
      }
      amp-img.cover img {
        object-fit: cover;
      }
      amp-script {
        opacity: 1;
      }
      amp-image-lightbox {
        background: var(--overlay-bg-clr);
      }
      .btn-gradient {
        -webkit-transform: skew(-15deg);
        transform: skew(-15deg);
        background-image: -webkit-gradient(linear, left top, right top, from(#2af598), to(#009efd));
        background-image: -webkit-linear-gradient(left, #2af598 0%, #009efd 100%);
        background-image: linear-gradient(90deg, #2af598 0%, #009efd 100%);
        -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
        box-shadow: 0 5px 10px rgba(0, 0, 0, .1);

        &:hover {
          -webkit-transform: skew(0);
          transform: skew(0);
          -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, .1);
          box-shadow: 0 2px 3px rgba(0, 0, 0, .1);
        }
      }
      /**** Common Design End ****/
`;

export default ResetCss;
