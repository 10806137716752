import * as Sentry from "@sentry/node";
import { isProductionEnv } from "~/lib/utils/environment";

// write log message to console in development
export function devLog(message?: string, ...optionalParams: []): void {
  if (
    process.env.NODE_ENV === "development" ||
    process.env.NODE_ENV === "test"
  ) {
    // eslint-disable-next-line no-console
    console.log("console.log (only dev)", message, ...optionalParams);
  }
}

// Stored state can only be obtained inside of the body of a function component
// When caller is not inside of the body of a function component, set skipState is true
export function errorWithoutThrowInProduction({
  errorMessage,
  errorInfo,
}: {
  errorMessage: string;
  errorInfo?: any;
}): void {
  if (
    process.env.NODE_ENV === "development" ||
    process.env.NODE_ENV === "test"
  ) {
    throw new Error(
      `${errorMessage}
Detail:
${JSON.stringify(errorInfo, null, 2)}`
    );
  }

  Sentry.withScope((scope: Sentry.Scope) => {
    scope.setExtras({ errorMessage, ...errorInfo });
    Sentry.captureException(Error(errorMessage));
  });
}

export function reportError({
  errorMessage,
  errorInfo,
}: {
  errorMessage: string;
  errorInfo?: any;
}) {
  // Only if you are connected to the production Firebase
  if (process.env.NODE_ENV === "production" && isProductionEnv()) {
    Sentry.withScope((scope: Sentry.Scope) => {
      scope.setExtras({ errorMessage, ...errorInfo });
      Sentry.captureException(Error(errorMessage));
    });
  } else {
    // eslint-disable-next-line no-console
    console.error(`[dev only] ${errorMessage}
Detail:
${JSON.stringify(errorInfo, null, 2)}`);
  }
}
