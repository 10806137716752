import { Box, Text } from "@chakra-ui/react";
import Image from "components/atoms/Image";
import { colorBlack, KIKUSHIRU_BASE_URL } from "lib/constants";
import { unixTimestampToDate } from "lib/utils/dateHelper";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import React, { VFC, memo } from "react";
import styled from "styled-components";
import { Project } from "~/models/project";

interface Props {
  project?: Project;
}

const FooterPresenter: VFC<Props> = () => {
  const { t } = useTranslation();
  const currentYear = unixTimestampToDate(Date.now() / 1000, "Y");
  return (
    <Box
      id="footer"
      bgColor="black.30"
      borderTop={`1px solid ${colorBlack[50]}`}
      p="40px 16px 100px 16px"
      textAlign="center"
      position="relative"
      zIndex={10}
    >
      <div className="content-wrap">
        <Box color="black.400" fontSize="16px" lineHeight="30px">
          <Text as="span" display="inline-block" mb={4}>
            <ServiceSiteLink
              href={KIKUSHIRU_BASE_URL}
              target="_blank"
              rel="noopener"
            >
              © {currentYear}{" "}
              {t("common:kikushiru-site-name", {}, { fallback: "キクシル" })}
            </ServiceSiteLink>
          </Text>
        </Box>
        <ContactMail>
          問合せ：
          <Link href="mailto:contact@kikushiru.com">
            <a target="_blank">contact@kikushiru.com</a>
          </Link>
        </ContactMail>
        <QuestionMarkLink
          href={KIKUSHIRU_BASE_URL}
          target="_blank"
          rel="noopener"
          aria-label="キクシル"
        >
          <Image
            alt="キクシル ロゴ"
            src="/img/question-mark.svg"
            width="40"
            height="40"
            disableHover={true}
          />
        </QuestionMarkLink>
      </div>
    </Box>
  );
};

const ContactMail = styled.div`
  margin: 0 0 24px 0;

  a {
    color: ${colorBlack[400]};

    &:hover {
      opacity: 0.8;
      text-decoration: underline;
    }
  }
`;

const ServiceSiteLink = styled.a`
  color: ${colorBlack[400]};
  &:hover {
    opacity: 0.8;
    text-decoration: underline;
  }
`;

const QuestionMarkLink = styled.a`
  display: block;
`;

export default memo(FooterPresenter);
