import fetch from "isomorphic-unfetch";
import { errorWithoutThrowInProduction } from "~/lib/logging/logHelper";

// Initial creation of UserStatus
export async function createUserStatusApi(): Promise<{ isSuccess: boolean }> {
  const res = await fetch(`/api/userStatuses/createUserStatus`, {
    method: "POST",
  });

  const isSuccess = res.status === 200;
  const resJson = await res.json();

  if (!isSuccess) {
    errorWithoutThrowInProduction({
      errorInfo: { resJson },
      errorMessage: "Fail createUserStatusApi api call",
    });
  }

  return { isSuccess };
}
