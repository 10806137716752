import { UseToastOptions } from "@chakra-ui/react";
import { Styles } from "react-modal";
import { css, keyframes } from "styled-components";
import {
  breakPoint,
  colorBlack,
  colorImportant,
  CONTENT_MAX_WIDTH,
} from "lib/constants";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const contentWrap = () => css`
  padding-left: 16px;
  padding-right: 16px;
  max-width: ${CONTENT_MAX_WIDTH + 32}px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (min-width: ${breakPoint["md"]}) {
    padding-left: 24px;
    padding-right: 24px;
    max-width: ${CONTENT_MAX_WIDTH + 48}px;
  }
`;

export const contentFullWrap = () => css`
  width: 100%;
  max-width: ${CONTENT_MAX_WIDTH}px;
  margin-left: auto;
  margin-right: auto;
`;

export const myPageContentWrap = () => css`
  max-width: ${breakPoint["md"]};
  padding-left: 24px;
  padding-right: 24px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: ${breakPoint["sm"]}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const myPageContentFullWrap = () => css`
  width: 100%;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
`;

export const centerTitle = () => css`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: var(--body-clr);
  text-align: center;
`;

export const limitedLineContent = () => css`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-box-orient: vertical;
`;

export const oneLineContent = () => css`
  ${limitedLineContent};
  -webkit-line-clamp: 1;
`;

export const twoLineContent = () => css`
  ${limitedLineContent};
  -webkit-line-clamp: 2;
`;

export const formLabelStyle = () => css`
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: var(--body-clr);
`;

export const formInputStyle = ({
  hasDataError,
}: {
  hasDataError?: boolean;
}) => css`
  background-color: var(--body-bg-clr);
  border: 1px solid var(--pri-clr500);
  border-radius: 5px;
  color: var(--body-clr);
  font-size: 16px;
  line-height: 30px;
  overflow-anchor: none;
  padding: 12px 16px;
  width: 100%;

  ${() => {
    if (hasDataError) {
      return css`
        border: 1px solid ${colorImportant[500]};
      `;
    }
  }};
`;

export const questionText = () => css`
  display: block;
  font-weight: 700;
  font-size: 28px;
  line-height: 48px;
  color: var(--title-clr);

  @media only screen and (max-width: ${breakPoint["sm"]}) {
    font-size: 24px;
    line-height: 40px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const formField = () => css`
  align-items: center;
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 16px;
  margin-bottom: 16px;
`;

export const unstyledButton = css`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
`;

export const hideScrollbar = css`
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const modalStyle: Styles = {
  content: {
    background: "#fff",
    border: "none",
    borderRadius: "0",
    height: "100%",
    inset: "unset",
    overflowY: "auto",
    padding: "16px",
    position: "unset",
  },
  overlay: {
    background: "#fff",
    height: "100%",
    overflow: "hidden",
    zIndex: 1001,
  },
};

export const bottomSpeechBubbleBox = () => css`
  background: var(--md-cmt-bg-clr);
  border: 2px solid var(--box-bdr-clr);
  border-radius: 5px;
  color: var(--body-clr);
  font-size: 16px;
  line-height: 30px;
  overflow-wrap: anywhere;
  padding: 16px;
  position: relative;

  @media only screen and (max-width: ${breakPoint["sm"]}) {
    padding: 12px;
  }

  @media only screen and (min-width: ${breakPoint["md"]}) {
    font-size: 18px;
    line-height: 36px;
  }

  &::after {
    border-color: var(--box-bdr-clr) transparent transparent;
    border-style: solid;
    border-width: 14px 10px;
    bottom: -28px;
    content: "";
    height: 0;
    left: 2px;
    position: absolute;
    width: 0;
    z-index: 1;
  }

  &::before {
    border-color: var(--body-bg-clr) transparent transparent;
    border-style: solid;
    border-width: 12px 8px;
    bottom: -23px;
    content: "";
    height: 0;
    left: 4px;
    position: absolute;
    width: 0;
    z-index: 2;
  }
`;

export const summaryTable = () => css`
  font-size: 16px;
  line-height: 30px;
  margin-top: 16px;
  width: 100%;

  > thead,
  > tbody {
    border-bottom: 2px solid ${colorBlack[30]};
  }

  > tbody > tr {
    &:nth-child(even) {
      background-color: ${colorBlack[30]};
    }
  }

  th,
  td {
    padding: 12px;
    @media only screen and (max-width: ${breakPoint["xs"]}) {
      padding: 8px;
    }
  }

  th {
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
  }
`;

export const explanationText = css`
  color: ${colorBlack[400]};
  font-size: 16px;
  line-height: 30px;
  margin-top: 16px;
`;

export const snsIconLabel = css`
  color: var(--b-clr200);
  display: block;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  margin-top: 2px;
  text-align: center;
`;

export const likeButtonPulse = keyframes`
  50% { transform: scale(1.2); }
`;

export const likeButtonExplode = keyframes`
  0% { opacity: 1; }
  100% { transform: scale(1.5) translate(-12.5%, -12.5%); opacity: 0; }
`;

/* Style object */
export const hideScrollbarStyles = {
  /* Hide scrollbar for Chrome, Safari and Opera */
  "::-webkit-scrollbar": {
    display: "none",
  },
  msOverflowStyle: "none" /* IE and Edge */,
  scrollbarWidth: "none" /* Firefox */,
};

export const descriptionStyles = {
  color: "black.700",
  fontSize: "22px",
  fontWeight: "bold",
  lineHeight: "36px",
  textAlign: "center",
};

export const uploadingToast: UseToastOptions = {
  description: "画像アップロード中",
  duration: 10000,
  position: "bottom-right",
  status: "loading",
  variant: "subtle",
};
