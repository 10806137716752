import { createUser } from "~/iterators/firestore/users/createUser";
import { retrieveUser } from "~/iterators/firestore/users/retrieveUser";
import { getFullProfileImageUrl, User } from "~/models/user";
import { updateUserDefaultImageApi } from "mypage/src/iterators/externalApi/users/updateUserDefaultImageApi";
import { createUserStatusApi } from "mypage/src/iterators/internalApi/userStatuses/createUserStatusApi";

export async function createOrFindUserService({
  email,
  firebaseIdToken,
  name,
  originalImageUrl,
  userId,
}: {
  email?: string | null;
  firebaseIdToken: string;
  name?: string | null;
  originalImageUrl?: string | null;
  userId?: string | null;
}): Promise<{ isSuccess: boolean; user?: User }> {
  if (!userId) {
    throw Error("Invalid uid");
  }

  let user = await retrieveUser({ userId });

  if (user) {
    return { isSuccess: true, user };
  }

  if (!originalImageUrl) {
    return { isSuccess: false, user: undefined };
  }

  // upload user default image to firestore
  const { imageUrl } = await updateUserDefaultImageApi({
    firebaseIdToken,
    imageUrl: getFullProfileImageUrl(originalImageUrl),
    userId,
  });

  if (!imageUrl) {
    return { isSuccess: false, user: undefined };
  }

  user = await createUser({
    email: email as string,
    imageUrl,
    name: name as string,
    originalImageUrl: originalImageUrl as string,
    userId,
  });
  const { isSuccess } = await createUserStatusApi();

  return { isSuccess: !!user && isSuccess, user };
}
