import { TSelectOption } from "~/components/atoms/form/SelectField";

export const FIRESTORE_COLLECTION_NAME_PROJECT = "projects";
export const FIRESTORE_COLLECTION_NAME_PROJECT_PRIVATE = "private";
export const FIRESTORE_COLLECTION_NAME_PROJECT_USER_SHARE = "userShare";

export const PROJECT_ID_KIKUSHIRU = "kikushiru.com";
export const PROJECT_ID_FAQ_PRICE_SALE = "faq.price-sale.com";
export const PROJECT_ID_GALAXY_FAQ_PRICE_RANK = "galaxy-faq.price-rank.com";
export const PROJECT_ID_PRICE_RANK_DEV = "price-rank.dev";
export const PROJECT_ID_MINING_PRICE_RANK = "mining.price-rank.com";
export const PROJECT_ID_SUGOWISH = "blog.sugowish.com";

export const DOMAIN_GAME_QUESTION = "game-question.com";
export const DOMAIN_PRICE_RANK_DEV = "team.morizyun.com";
export const DOMAIN_PRICE_RANK_DEV_VN = "vn-team.morizyun.com";

export interface Project {
  androidIcon192Path?: string | null;
  appleIcon114Path?: string | null;
  appleIcon120Path?: string | null;
  appleIcon144Path?: string | null;
  appleIcon152Path?: string | null;
  appleIcon180Path?: string | null;
  appleIcon57Path?: string | null;
  appleIcon60Path?: string | null;
  appleIcon72Path?: string | null;
  appleIcon76Path?: string | null;
  createdUnixTime: number;
  cvDomains: string[];
  defaultJobTitle: string;
  defaultLocale: string;
  domain: string;
  encourageToQuestionMessage: string | null;
  googleAdsenseClientId: string | null;
  favicon16Path?: string | null;
  favicon32Path?: string | null;
  favicon96Path?: string | null;
  faviconPath?: string | null;
  googleAnalyticsId: string | null;
  headerQuestionText?: string | null;
  id:
    | "blog.sugowish.com"
    | "faq.price-sale.com"
    | "kikushiru.com"
    | "galaxy-faq.price-rank.com"
    | "mining.price-rank.com"
    | "price-rank.dev";
  logoPath: string;
  msIcon144Path?: string | null;
  ogImageSiteIcon?: string | null;
  ownerName: string;
  qaCount: number;
  showGameQuestionSuggestion?: boolean;
  showGameWriterWanted: boolean;
  showQuestionButton: boolean;
  siteDescription: string;
  siteName: string;
  siteSubText?: string;
  supportLocales: string[];
  titleImagePath?: string | null;
  titleImageHeight?: number | null;
  titleImageWidth?: number | null;
  titleSupplementaryText: string | null;
  updatedUnixTime: number;
}

export interface ProjectIDictionary {
  [index: string]: Project;
}

// verify a string as a projectId
export function isProjectId(projectId: any): projectId is Project["id"] {
  return typeof projectId === "string";
}

export function generateProjectOptions({
  allOptionText,
  projects,
  sort = "qaCount",
}: {
  allOptionText?: string;
  projects?: Project[] | undefined;
  sort?: "none" | "qaCount";
}): TSelectOption[] {
  // order project by qaCount
  const sortedProjects =
    sort === "qaCount" ? projects?.sort(projectQaCountSort) : projects;

  const options = sortedProjects
    ? sortedProjects.map((t) => ({ text: t.siteName, value: t.id }))
    : [];

  // prepend all option
  if (allOptionText) {
    options.unshift({ text: allOptionText, value: "" as Project["id"] });
  }

  return options;
}

export function toProjectIDictionary(
  projects: Project[] | undefined
): ProjectIDictionary {
  if (!projects || !projects?.length) {
    return {};
  }

  return projects?.reduce(
    (arr: ProjectIDictionary, project) => ((arr[project.id] = project), arr),
    {}
  );
}

export function projectQaCountSort(a: Project, b: Project) {
  return (b.qaCount ?? 0) - (a.qaCount ?? 0);
}

export function getDomainForProject(project: Project) {
  return project.domain || project.id;
}
