import { UrlObject } from "url";
import { Box } from "@chakra-ui/react";
import { breakPoint } from "lib/constants";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import Image from "~/components/atoms/Image";
import SquareButton from "~/components/atoms/button/SquareButton";
import { myPageContentWrap } from "~/styles/CommonStyles";
import { pagesPath, staticPath } from "mypage/src/lib/$path";
import { DeviceMode } from "../../layout";
import SideMenu from "./SideMenu";
import LogoAndTitle from "./logoAndTitle";

interface Props {
  deviceMode?: DeviceMode;
  isShowSideMenu: boolean;
  logoUrl: string;
  mainTitle: string;
  mainTitleUrl: UrlObject;
  onDeviceSwitcherCallback?: () => void;
  talkRoomId?: string;
}

const hideBackButtonPaths: string[] = [
  pagesPath.login.$url().pathname,
  pagesPath.logout.$url().pathname,
  pagesPath.tasks.sendRequest.thanks.$url().pathname,
  pagesPath.tasks._taskId("").endReport.thanks.$url().pathname,
];

const hideHomeButtonPaths: string[] = [
  pagesPath.home.$url().pathname,
  pagesPath.login.$url().pathname,
  pagesPath.logout.$url().pathname,
];

const historyStoreKey = "mypage::history";

const HeaderSideMenuWrap: FC<Props> = ({
  deviceMode,
  isShowSideMenu,
  logoUrl,
  mainTitle,
  mainTitleUrl,
  onDeviceSwitcherCallback,
  talkRoomId,
}) => {
  const router = useRouter();
  const [isShowBack, setIsShowBack] = useState<boolean>(false);
  const [isShowDeviceSwitcher, setIsShowDeviceSwitcher] = useState<boolean>(
    !!deviceMode
  );
  const { t } = useTranslation();

  useEffect(() => {
    const historyUrls = localStorage.getItem(historyStoreKey)?.split(";") || [];
    // remove duplicate latest history
    if (
      historyUrls.length > 0 &&
      historyUrls[historyUrls.length - 1] === router.asPath
    ) {
      historyUrls.pop();
    }
    // hide back button if current path is in hideBackButtonPaths
    if (!hideBackButtonPaths.includes(router.pathname)) {
      localStorage.setItem(
        historyStoreKey,
        `${[...historyUrls, router.asPath].join(";")}`
      );
      if (historyUrls.length > 0) {
        setIsShowBack(true);
      }
    }
  }, [router.pathname, router.asPath]);

  const goBack = () => {
    const historyUrls = localStorage.getItem(historyStoreKey)?.split(";") || [];
    if (historyUrls.length > 1) {
      // remove last history when go back
      historyUrls.pop();
      localStorage.setItem(historyStoreKey, `${historyUrls.join(";")}`);
      router.push(historyUrls[historyUrls.length - 1]);
    }
  };

  useEffect(() => {
    setIsShowDeviceSwitcher(!!deviceMode);
  }, [deviceMode]);

  const isShowHome =
    router.asPath?.length >= 1 && !hideHomeButtonPaths.includes(router.asPath);

  return (
    <Wrap
      isShowBack={isShowBack}
      isShowHome={isShowHome}
      isShowTalkRoom={!!talkRoomId}
      isShowDeviceSwitcher={!!isShowDeviceSwitcher}
    >
      {isShowBack && (
        <div onClick={goBack}>
          <SquareButton
            variant="back"
            text={t("mypage:header.square-btn-back", {}, { fallback: "戻る" })}
          />
        </div>
      )}
      {isShowHome && (
        <Link href={pagesPath.home.$url()} passHref>
          <a>
            <SquareButton
              variant="home"
              text={t(
                "mypage:header.square-btn-home",
                {},
                { fallback: "ホーム" }
              )}
            />
          </a>
        </Link>
      )}
      {talkRoomId && (
        <Link
          href={pagesPath.talkRooms._talkRoomId(talkRoomId).$url()}
          passHref
        >
          <a>
            <SquareButton
              variant="talk"
              text={t(
                "mypage:header.square-btn-talk",
                {},
                { fallback: "トーク" }
              )}
            />
          </a>
        </Link>
      )}
      {isShowDeviceSwitcher && (
        <Box
          sx={{
            "@media screen and (max-width: 768px)": {
              display: "none",
            },
          }}
          cursor="pointer"
          display="inline-grid"
          onClick={onDeviceSwitcherCallback}
        >
          <Image
            src={
              deviceMode === "pc"
                ? staticPath.img.switcher_pc_svg
                : staticPath.img.switcher_sp_svg
            }
            alt="switcher"
            width={68}
            height={40}
          />
        </Box>
      )}
      <LogoAndTitle
        logoUrl={logoUrl}
        title={mainTitle}
        titleUrl={mainTitleUrl}
      />
      {isShowSideMenu && <SideMenu />}
    </Wrap>
  );
};

function getColumn(props: {
  isShowBack: boolean;
  isShowHome: boolean;
  isShowTalkRoom: boolean;
  isShowDeviceSwitcher: boolean;
}) {
  return `${props.isShowBack ? "40px " : ""}${props.isShowHome ? "40px " : ""}${
    props.isShowTalkRoom ? "40px " : ""
  }${props.isShowDeviceSwitcher ? "68px " : ""}minmax(60px, 660px) 36px`;
}

const Wrap = styled.div<{
  isShowBack: boolean;
  isShowHome: boolean;
  isShowTalkRoom: boolean;
  isShowDeviceSwitcher: boolean;
}>`
  ${myPageContentWrap}

  display: grid;
  grid-template-columns: ${(props) => getColumn(props)};
  grid-column-gap: 16px;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @media only screen and (max-width: ${breakPoint["md"]}) {
    grid-template-columns: ${(props) =>
      getColumn({ ...props, isShowDeviceSwitcher: false })};
  }

  @media only screen and (max-width: ${breakPoint["xs"]}) {
    grid-column-gap: 12px;
  }
`;

export default HeaderSideMenuWrap;
