import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Router, { useRouter } from "next/router";
import * as React from "react";
import "sanitize.css";
import { ThemeProvider } from "styled-components";
import ToastContainer from "~/components/atoms/ToastContainer";
import { chakraTheme } from "~/styles/ChakraTheme";
import { DefaultMetaTag } from "mypage/src/components/modules/metaTag";
import PageLoadingSkeleton from "mypage/src/components/modules/skeleton/PageLoadingSkeleton";
import { pagesPath } from "mypage/src/lib/$path";
import { AuthProvider } from "mypage/src/lib/auth/AuthProvider";

const theme = {};

const queryClient = new QueryClient();

export default function AppLayout({
  children,
}: {
  children: React.ReactElement;
}) {
  const [loading, setLoading] = React.useState(false);
  const router = useRouter();
  // skip loading for login/logout page to avoid infinite loading
  const skipPaths: string[] = React.useMemo(
    () => [pagesPath.login.$url().pathname, pagesPath.logout.$url().pathname],
    []
  );

  React.useEffect(() => {
    if (!router.pathname || skipPaths.includes(router.pathname)) {
      return;
    }

    const start = () => {
      setLoading(true);
    };
    const end = () => {
      setLoading(false);
    };
    Router.events.on("routeChangeStart", start);
    Router.events.on("routeChangeComplete", end);
    Router.events.on("routeChangeError", end);
    return () => {
      Router.events.off("routeChangeStart", start);
      Router.events.off("routeChangeComplete", end);
      Router.events.off("routeChangeError", end);
    };
  }, [router.pathname, skipPaths]);

  return (
    <ThemeProvider theme={theme}>
      <ChakraProvider theme={chakraTheme}>
        {/* Refer https://github.com/catnose99/next-head-seo#default-seo-settings */}
        <DefaultMetaTag />
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            {loading ? <PageLoadingSkeleton /> : children}
            <ToastContainer />
          </AuthProvider>
        </QueryClientProvider>
      </ChakraProvider>
    </ThemeProvider>
  );
}
