import {
  DocumentData,
  PartialWithFieldValue,
  SnapshotOptions,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { validateUserStatus } from "~/iterators/mappers/validator/userStatus";
import { extractNonUndefined } from "~/lib/utils/objectHelper";
import { DEFAULT_USER_STATUS_ROLE, UserStatus } from "~/models/userStatus";

export const userStatusConverter = {
  fromFirestore(
    snapshot: FirebaseFirestore.DocumentSnapshot | QueryDocumentSnapshot,
    options?: SnapshotOptions
  ): UserStatus {
    const data = options ? snapshot.data(options) : snapshot.data();
    const userStatus = {
      concurrentSelfTask: data?.concurrentSelfTask ?? 0,
      createTaskOnCompletion: data?.createTaskOnCompletion ?? true,
      createdUnixTime: data?.createdUnixTime,
      id: snapshot.id,
      lastTalkroomId: data?.lastTalkroomId || null,
      remindTaskCreation: data?.remindTaskCreation ?? true,
      role: data?.role || DEFAULT_USER_STATUS_ROLE,
      teamId: data?.teamId || null,
      updatedUnixTime: data?.updatedUnixTime,
    };

    validateUserStatus(userStatus);

    return userStatus;
  },
  toFirestore(userStatus: PartialWithFieldValue<UserStatus>): DocumentData {
    return extractNonUndefined(userStatus);
  },
};
