import { Box, Grid, Skeleton } from "@chakra-ui/react";
import React, { FC } from "react";
import Layout from "mypage/src/components/layout";

const PageLoadingSkeleton: FC = () => (
  <Layout hideHeader hideFooter>
    <>
      <Grid
        alignItems="center"
        templateColumns="auto auto"
        justifyContent="space-between"
        maxW="768px"
        w="100%"
        m="0 auto"
        p="4px 16px"
      >
        <Skeleton width={60} height={14} />
        <Skeleton width={9} height={6} />
      </Grid>
      <Box minH="calc(100vh)" maxW="768px" w="100%" m="0 auto" p="0 16px">
        <Skeleton height={12} />
        <Box maxW="768px" w="100%" m="0 auto" p="0 16px">
          <Skeleton height={12} width={200} mt={6} />
          <Skeleton height={20} mt={6} />
          <Skeleton height={20} mt={6} />
        </Box>
      </Box>
    </>
  </Layout>
);

export default PageLoadingSkeleton;
