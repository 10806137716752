import { appendFirestoreBasicColumn } from "~/iterators/mappers/toFirebase/utils/appendFirestoreBasicColumn";
import {
  validateUser,
  validateUserCoverImageUrl,
  validateUserImageUrl,
  validateUserSpecialtyIds,
} from "~/iterators/mappers/validator/user";
import { extractNonUndefined } from "~/lib/utils/objectHelper";
import { User } from "~/models/user";

interface Props {
  blogUrl?: string | null;
  coverImageUrl?: string | null;
  description?: string | null;
  imageUrl?: string | null;
  instagramUrl?: string | null;
  jobTitle?: string | null;
  name?: string | null;
  portfolioUrl1?: string | null;
  portfolioUrl2?: string | null;
  specialtyIds?: string[] | null;
  twitterUrl?: string | null;
  visible?: boolean;
  youtubeUrl?: string | null;
}

export function getFirebaseUserForCreate({
  description,
  imageUrl,
  instagramUrl,
  name,
  twitterUrl,
  visible,
  youtubeUrl,
}: Props) {
  const userObj = {
    description: description || null,
    imageUrl: imageUrl || null,
    instagramUrl: instagramUrl || null,
    name: name || null,
    twitterUrl: twitterUrl || null,
    visible: visible || false,
    youtubeUrl: youtubeUrl || null,
  } as User;

  validateUser(userObj);

  return appendFirestoreBasicColumn({ obj: userObj });
}

export type UserUpdateParams = Partial<
  Pick<
    User,
    | "articleCount"
    | "articleFbHelpfulCount"
    | "articleFbHelpfulCountLast30Days"
    | "articlePageViewCount"
    | "articlePageViewCountLast30Days"
    | "blogUrl"
    | "description"
    | "imageUrl"
    | "coverImageUrl"
    | "instagramUrl"
    | "jobTitle"
    | "name"
    | "portfolioUrl1"
    | "portfolioUrl2"
    | "supportAdClickCount"
    | "twitterUrl"
    | "visible"
    | "youtubeUrl"
  >
>;

export function getFirebaseUserForUpdate(params: UserUpdateParams) {
  return appendFirestoreBasicColumn({
    isUpdate: true,
    obj: extractNonUndefined(params),
  });
}

export function getFirebaseUserForUpdateImage({ imageUrl }: Props) {
  const obj = {} as User;
  if (imageUrl || imageUrl === null) {
    obj["imageUrl"] = imageUrl;
  }

  validateUserImageUrl(obj);

  return appendFirestoreBasicColumn({
    isUpdate: true,
    obj,
  });
}

export function getFirebaseUserForUpdateCoverImage({ coverImageUrl }: Props) {
  const obj = {} as User;
  if (coverImageUrl || coverImageUrl === null) {
    obj["coverImageUrl"] = coverImageUrl;
  }

  validateUserCoverImageUrl(obj);

  return appendFirestoreBasicColumn({
    isUpdate: true,
    obj,
  });
}

export function getFirebaseUserForUpdateSpecialities({ specialtyIds }: Props) {
  const obj = {} as User;
  if (specialtyIds) {
    obj["specialtyIds"] = specialtyIds;
  }

  validateUserSpecialtyIds(obj);

  return appendFirestoreBasicColumn({
    isUpdate: true,
    obj,
  });
}
