import { differenceInDays } from "date-fns";
import {
  zonedTimeToUtc,
  utcToZonedTime,
  format as dateFnsFormat,
} from "date-fns-tz";

// default client time zone
const clientTimeZone = "Asia/Tokyo";
type DateFormat =
  | "Y/M/D H:M"
  | "Y/M/D H:M:S"
  | "Y-M-D"
  | "Y年M月D日 H:M"
  | "Y/M/D"
  | "H:M"
  | "ISO"
  | "Y年M月"
  | "Y"
  | "M/D"
  | "m/D"
  | "M/D H:M";

// add leading zero to number
export function padNum(number: number): string {
  return number.toString().padStart(2, "0");
}

export const unixTimestampToDate = (
  unixTimestampToDate: number,
  format: DateFormat,
  lang?: string
): string => {
  if (!unixTimestampToDate || (lang && !["en", "ja"].includes(lang))) {
    return "";
  }

  try {
    const date = new Date(
      // The same time is displayed for en-US / ja-JP
      new Date(unixTimestampToDate * 1000).toLocaleString("ja-JP", {
        timeZone: "Asia/Tokyo",
      })
    );
    const year = date.getFullYear();
    const month = padNum(date.getMonth() + 1);
    const day = padNum(date.getDate());
    const hour = padNum(date.getHours());
    const minute = padNum(date.getMinutes());
    switch (format) {
      case "Y/M/D":
        return `${year}/${month}/${day}`;
      case "Y/M/D H:M":
        return `${year}/${month}/${day} ${hour}:${minute}`;
      case "Y-M-D":
        return `${year}-${month}-${day}`;
      case "Y年M月D日 H:M":
        return lang === "en"
          ? `${year}/${month}/${day} ${hour}:${minute}`
          : `${year}年${month}月${day}日 ${hour}:${minute}`;
      case "H:M":
        return `${hour}:${minute}`;
      case "ISO":
        return date.toISOString();
      case "Y年M月":
        return lang === "en" ? `${year}/${month}` : `${year}年${month}月`;
      case "Y":
        return `${year}`;
      case "M/D H:M":
        return `${month}/${day} ${hour}:${minute}`;
      default:
        return "";
    }
  } catch (e) {
    return "";
  }
};

// convert unix timestamp to date object
export const unixTimestampToDateObject = (unixTimestamp: number): Date => {
  return new Date(
    new Date(unixTimestamp * 1000).toLocaleString("ja-JP", {
      timeZone: "Asia/Tokyo",
    })
  );
};

export const formatDate = (date: Date, format: DateFormat): string => {
  if (!date) {
    return "";
  }

  try {
    const year = date.getFullYear();
    const month = padNum(date.getMonth() + 1);
    const day = padNum(date.getDate());
    const hour = padNum(date.getHours());
    const minute = padNum(date.getMinutes());
    const second = padNum(date.getSeconds());
    switch (format) {
      case "Y/M/D":
        return `${year}/${month}/${day}`;
      case "Y/M/D H:M":
        return `${year}/${month}/${day} ${hour}:${minute}`;
      case "Y/M/D H:M:S":
        return `${year}/${month}/${day} ${hour}:${minute}:${second}`;
      case "Y-M-D":
        return `${year}-${month}-${day}`;
      case "Y年M月D日 H:M":
        return `${year}年${month}月${day}日 ${hour}:${minute}`;
      case "H:M":
        return `${hour}:${minute}`;
      case "ISO":
        return date.toISOString();
      case "Y年M月":
        return `${year}年${month}月`;
      case "Y":
        return `${year}`;
      case "M/D":
        return `${month}/${day}`;
      case "m/D":
        return `${parseInt(month)}/${day}`;
      case "M/D H:M":
        return `${month}/${day} ${hour}:${minute}`;
      default:
        return "";
    }
  } catch (e) {
    return "";
  }
};

type TimeFormat = undefined | "beginningOfDay" | "endOfDate" | "keepTime";
export const dateToUnixTimestamp = (
  dateToUnixTimestamp: Date,
  timeFormat?: TimeFormat
): number => {
  try {
    if (!timeFormat) {
      return parseInt((dateToUnixTimestamp.getTime() / 1000).toFixed(0));
    }

    const year = dateToUnixTimestamp.getFullYear();
    const month = padNum(dateToUnixTimestamp.getMonth() + 1);
    const day = padNum(dateToUnixTimestamp.getDate());
    // get date in JP zone
    switch (timeFormat) {
      case "beginningOfDay":
        return parseInt(
          (
            new Date(`${year}-${month}-${day}T00:00:00.000+09:00`).getTime() /
            1000
          ).toFixed(0)
        );
      case "endOfDate":
        return parseInt(
          (
            new Date(`${year}-${month}-${day}T23:59:59.000+09:00`).getTime() /
            1000
          ).toFixed(0)
        );
      case "keepTime": {
        const hour = padNum(dateToUnixTimestamp.getHours());
        const minute = padNum(dateToUnixTimestamp.getMinutes());
        const second = padNum(dateToUnixTimestamp.getSeconds());
        return parseInt(
          (
            new Date(
              `${year}-${month}-${day}T${hour}:${minute}:${second}.000+09:00`
            ).getTime() / 1000
          ).toFixed(0)
        );
      }
    }
  } catch (e) {
    return 0;
  }
};

export const getCurrentDate = (): string => {
  const today = new Date();
  return `${today.getFullYear()}${padNum(today.getMonth() + 1)}${padNum(
    today.getDate()
  )}`;
};

export function getDaysFromToday(date: Date): number {
  return differenceInDays(new Date(), date);
}

export const getTodayDate = (): Date => {
  return new Date(
    new Intl.DateTimeFormat("ja-JP", {
      day: "2-digit",
      hour: "2-digit",
      hour12: false,
      minute: "2-digit",
      month: "2-digit",
      second: "2-digit",
      timeZone: "Asia/Tokyo",
      year: "numeric",
    }).format(new Date())
  );
};

export const dateAgoFromToday = (dayAgo: number): Date => {
  if (!dayAgo) {
    return new Date();
  }

  return new Date(Date.now() - dayAgo * 24 * 60 * 60 * 1000);
};

export function isUnixTimeIn(unixTime: number, seconds: number): boolean {
  return new Date().getTime() / 1000 - unixTime <= seconds;
}

//==========================================================================
// UTC date time conversion
export function formatUTCDate(utcDate: Date, format: DateFormat): string {
  try {
    const jpDate = utcToZonedTime(utcDate, clientTimeZone);
    switch (format) {
      case "Y/M/D":
        return dateFnsFormat(jpDate, "yyyy/MM/dd");
      case "Y/M/D H:M":
        return dateFnsFormat(jpDate, "yyyy/MM/dd HH:mm");
      case "Y/M/D H:M:S":
        return dateFnsFormat(jpDate, "yyyy/MM/dd HH:mm:ss");
      case "Y-M-D":
        return dateFnsFormat(jpDate, "yyyy-MM-dd");
      case "Y年M月D日 H:M":
        return dateFnsFormat(jpDate, "yyyy年MM月dd日 HH:mm");
      case "H:M":
        return dateFnsFormat(jpDate, "HH:mm");
      case "ISO":
        return dateFnsFormat(jpDate, "yyyy-MM-dd'T'HH:mm:ss.000'Z'");
      case "Y年M月":
        return dateFnsFormat(jpDate, "yyyy年MM月");
      case "Y":
        return dateFnsFormat(jpDate, "yyyy");
      case "M/D":
        return dateFnsFormat(jpDate, "MM/dd");
      case "m/D":
        return dateFnsFormat(jpDate, "M/dd");
      case "M/D H:M":
        return dateFnsFormat(jpDate, "MM/dd HH:mm");
      default:
        return "";
    }
  } catch (e) {
    return "";
  }
}

export function getUTCDate(): Date {
  const today = new Date();
  return new Date(
    Date.UTC(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate(),
      today.getUTCHours(),
      today.getUTCMinutes(),
      today.getUTCSeconds(),
      today.getUTCMilliseconds()
    )
  );
}

export function unixTimeToUTCDate(unixTime: number | null): Date {
  if (!unixTime) {
    return getUTCDate();
  }
  const jpDate = new Date(
    // The same time is displayed for en-US / ja-JP
    new Date(unixTime * 1000).toLocaleString("ja-JP", {
      timeZone: clientTimeZone,
    })
  );
  const utcDate = zonedTimeToUtc(jpDate, clientTimeZone);
  return utcDate;
}

export function utcStringToUTCDate(utcString: string): Date {
  if (!utcString) {
    return getUTCDate();
  }

  try {
    return new Date(utcString);
  } catch {
    return getUTCDate();
  }
}

export function utcDateToUnixTime(utcDate: Date): number {
  try {
    const jpDate = utcToZonedTime(utcDate, clientTimeZone);
    return jpDate.getTime() / 1000;
  } catch {
    return 0;
  }
}

export function utcDateToClientDate(utcDate: Date): Date {
  return utcToZonedTime(utcDate, clientTimeZone);
}
