import { UrlObject } from "url";
import { pagesPath } from "faqSrc/lib/$path";
import { NextRouter } from "next/router";
import { HTMLAttributeAnchorTarget } from "react";
import * as zod from "zod";
import {
  KIKUSHIRU_MYPAGE_HOST,
  MYPAGE_URLS,
  PROJECT_IDS_REGEX,
} from "lib/constants";
import { errorWithoutThrowInProduction } from "~/lib/logging/logHelper";
import { isProductionEnv } from "~/lib/utils/environment";
import {
  DOMAIN_GAME_QUESTION,
  DOMAIN_PRICE_RANK_DEV,
  Project,
  PROJECT_ID_FAQ_PRICE_SALE,
  PROJECT_ID_KIKUSHIRU,
  PROJECT_ID_PRICE_RANK_DEV,
} from "~/models/project";
import { QuestionAnswer } from "~/models/questionAnswer";
import { Topic } from "~/models/topic";

export function isPriceRankDevVnSite(url: string): boolean {
  return /(vn.price-rank.dev|vn-price-rank-dev|vn-team.morizyun.com)/.test(url);
}

export function getServerBaseUrl(): string {
  if (
    process.env.NODE_ENV === "production" &&
    process.env.NEXT_PUBLIC_BASE_URL
  ) {
    return process.env.NEXT_PUBLIC_BASE_URL;
  }

  // May not be getting it right...
  if (process.env.NEXT_PUBLIC_VERCEL_URL) {
    return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
  }
  // Testing process.env.VERCEL_URL
  if (process.env.VERCEL_URL) {
    return `https://${process.env.VERCEL_URL}`;
  }

  return process.env.NEXT_PUBLIC_BASE_URL as string;
}

export function getBaseDomain(url?: string): string {
  const checkingUrl = url ?? getServerBaseUrl();
  return checkingUrl
    ? checkingUrl.replace("https://", "")?.replace("http://", "")
    : "";
}

function getProtocol(): string {
  return process.env.NODE_ENV === "development" ? "http" : "https";
}

export function getLocale({
  project,
  router,
}: {
  project?: Project;
  router: NextRouter;
}): string {
  if (!project?.supportLocales || project.supportLocales.length <= 1) {
    return "";
  }

  return router?.locale !== router?.defaultLocale ? `/${router?.locale}` : "";
}

export function getCurrentProjectId(): Project["id"] {
  const baseDomain = getBaseDomain();

  for (const [id, regex] of Object.entries(PROJECT_IDS_REGEX)) {
    if (baseDomain.match(regex)) {
      return id as Project["id"];
    }
  }

  return PROJECT_ID_KIKUSHIRU;
}

export function getFaqUrl(): string {
  if (process.env.NEXT_PUBLIC_FAQ_URL) {
    return process.env.NEXT_PUBLIC_FAQ_URL;
  }

  return `https://${getHostFromProjectId()}`;
}

export function getHostFromProjectId(projectId?: Project["id"]): string {
  const checkingProjectId = projectId ?? getCurrentProjectId();

  if (checkingProjectId === PROJECT_ID_PRICE_RANK_DEV) {
    return DOMAIN_PRICE_RANK_DEV;
  }

  // modify domain faq.price-sale.com to game-question.com
  return checkingProjectId === PROJECT_ID_FAQ_PRICE_SALE
    ? DOMAIN_GAME_QUESTION
    : checkingProjectId;
}

export function getMyPageDomain(): string {
  if (isProductionEnv()) {
    return KIKUSHIRU_MYPAGE_HOST;
  }

  if (process.env.NEXT_PUBLIC_MYPAGE_URL) {
    return process.env.NEXT_PUBLIC_MYPAGE_URL.replace("https://", "")?.replace(
      "http://",
      ""
    );
  }

  return "mypage-stg.kikushiru.com";
}

export function getMypageUrl(): string {
  if (isProductionEnv()) {
    const projectId = getCurrentProjectId();

    for (const [id, url] of Object.entries(MYPAGE_URLS)) {
      if (projectId === id) {
        return url;
      }
    }

    return `https://${KIKUSHIRU_MYPAGE_HOST}`;
  }

  if (process.env.NEXT_PUBLIC_MYPAGE_URL) {
    return process.env.NEXT_PUBLIC_MYPAGE_URL;
  }

  return "https://mypage-stg.kikushiru.com";
}

export function getBaseUrlFromProjectId(projectId: Project["id"]): string {
  return projectId ? `https://${projectId}` : "";
}

// get host and locale for questionAnswer, topic, subtopic based on questionAnswer.projectId and questionAnswer.locale
export function getQaHostAndLocaleForURL({
  isAbsolute = false,
  project,
  questionAnswer,
  router,
}: {
  // absolute path is used in share url
  isAbsolute?: boolean;
  project: Project;
  questionAnswer: QuestionAnswer;
  router?: NextRouter;
}): {
  host: string | undefined;
  locale: string | undefined;
} {
  const currentProjectId = getCurrentProjectId();
  // set host for difference project and absolute url
  if (
    isAbsolute ||
    isMypage() ||
    questionAnswer.projectId !== currentProjectId
  ) {
    return {
      host: project.domain,
      // skip locale when questionAnswer locale is default locale
      locale:
        questionAnswer.locale === project?.defaultLocale
          ? undefined
          : questionAnswer.locale,
    };
  }

  // set relative url for the same project and locale
  if (router?.locale && questionAnswer.locale === router.locale) {
    return {
      host: undefined,
      locale: undefined,
    };
  }

  // set relative url for the same project and differ locale
  return {
    // get current host without locale slug
    host: getBaseDomain(),
    // skip locale when questionAnswer locale is default locale
    locale:
      questionAnswer.locale === router?.defaultLocale
        ? undefined
        : questionAnswer.locale,
  };
}

// generate questionAnswer path based on questionAnswer.projectId
export function generateQaDetailPath({
  questionAnswer,
  topicSlug,
}: {
  questionAnswer: QuestionAnswer;
  topicSlug: string;
}): UrlObject {
  // report error log when qa data is invalid
  if (!questionAnswer || !questionAnswer.id) {
    errorWithoutThrowInProduction({
      errorInfo: { questionAnswer, topicSlug },
      errorMessage: "Invalid questionAnswer to generateQaDetailPath",
    });
  }

  return pagesPath.qa
    ._topicSlug(topicSlug)
    ._questionAnswerId(questionAnswer.slug || questionAnswer.id)
    .$url();
}

// generate question post path based on current topic and locale
export function generateQuestionPostPath({
  host,
  router,
  talkRoomId,
  topic,
}: {
  host?: string;
  router?: NextRouter;
  talkRoomId?: string;
  topic?: Topic;
}): UrlObject {
  // get locale from router
  const locale =
    router && router.locale !== router.defaultLocale ? `/${router.locale}` : "";
  // go to question input page when topic present
  if (topic?.id) {
    return {
      host: host ?? getBaseDomain(),
      pathname: `${locale}${pagesPath.questions.post.content.$url().pathname}`,
      protocol: getProtocol(),
      query: {
        topicId: topic.id,
        topicName: topic.name,
        topicSlug: topic.slug,
        ...(talkRoomId ? { talkRoomId } : {}),
      },
    };
  }
  // go to topic selection page
  return {
    host: host ?? getBaseDomain(),
    pathname: `${locale}${pagesPath.questions.post.topic.$url().pathname}`,
    protocol: getProtocol(),
  };
}

export function convertUrlObjectToString({
  locale,
  url,
}: {
  locale?: string;
  url: UrlObject;
}): string {
  const matchedParams = url?.pathname?.match(/\[.*?]/g);
  const urlHash = url.hash ? `#${url.hash}` : "";
  let urlString = url.pathname;
  if (matchedParams && matchedParams?.length >= 1) {
    for (const matchedParam of matchedParams) {
      urlString = urlString?.replace(
        matchedParam,
        // @ts-ignore
        url.query[matchedParam?.replace("[", "")?.replace("]", "")]
      );
    }
  }

  if (!url?.host) {
    return `${locale ? `/${locale}` : ""}${urlString}${urlHash}`;
  }

  function getHost(propUrlObject: UrlObject): string {
    if (process.env.NODE_ENV === "development" && !isMypage()) {
      return process.env.NEXT_PUBLIC_BASE_URL?.replace("http://", "").replace(
        "https://",
        ""
      ) as string;
    }

    return propUrlObject.host as string;
  }

  return `${getProtocol()}://${getHost(url)}${
    locale ? `/${locale}` : ""
  }${urlString}${urlHash}`;
}

export function convertToLinkHref({
  locale,
  host,
  url,
}: {
  locale?: string;
  host?: string;
  url: UrlObject;
}): string | UrlObject {
  return host
    ? convertUrlObjectToString({ locale, url: { ...url, host } })
    : url;
}

export function isAbsoluteUrl(url: string): boolean {
  const pattern = new RegExp("^(?:[a-z]+:)?//", "i");
  return pattern.test(url);
}

function isExternalUrl(url: string | UrlObject): boolean {
  const host = typeof url === "object" ? url.host : getBaseDomain(url);
  return !!host && !host.includes(getBaseDomain());
}

export function isMypage(): boolean {
  return getServerBaseUrl().includes("mypage");
}

export function convertToExternalUrl({
  url,
  domain,
}: {
  url: string;
  domain?: string;
}): string {
  if (!url) {
    return "#";
  }
  if (isAbsoluteUrl(url)) {
    return url;
  }

  if (domain) {
    return `${domain}${url}`;
  }

  return `https://${url}`;
}

export function convertToSocialUrl({
  url,
  socialName,
}: {
  url: string;
  socialName: "instagram" | "twitter" | "youtube";
}): string {
  if (!url) {
    return "#";
  }
  if (isAbsoluteUrl(url)) {
    return url;
  }

  // replace @ from url
  if (url.indexOf("@") === 0) {
    url = url.slice(1);
  }

  switch (socialName) {
    case "instagram":
      return `https://www.instagram.com/${url}`;
    case "twitter":
      return `https://twitter.com/${url}`;
    case "youtube":
      return `https://www.youtube.com/${url}`;
  }
}

export function extractTwitterAccountFromUrl(
  twitterUrl: string | undefined
): string | undefined {
  if (!twitterUrl) {
    return undefined;
  }

  try {
    const twitterAccount = twitterUrl.split("/")?.pop();
    if (!twitterAccount) {
      throw new Error("Can not extract twitter account from twitter url");
    }
    return twitterAccount;
  } catch (e: any) {
    errorWithoutThrowInProduction({
      errorInfo: { stack: e?.stack, twitterUrl },
      errorMessage: "Fail to extract twitter account from twitter url",
    });
    return undefined;
  }
}

export function getQueryAsString(query: string | string[] | undefined | null) {
  if (!query) {
    return;
  }

  if (Array.isArray(query)) {
    return query[0];
  }

  return query;
}

export function isProjectUrl(url: string) {
  const projectDomains = [...Object.keys(PROJECT_IDS_REGEX), getBaseDomain()];

  const domainForUrl = url
    .replace("http://", "")
    .replace("https://", "")
    .split("/")[0];

  // if url is relative, consider it as a project url
  if (!domainForUrl) {
    return true;
  }

  return projectDomains.includes(domainForUrl);
}

export function isUrl(text: string) {
  return zod.string().url().safeParse(text).success;
}

export function externalLinkAttrs(url: string | UrlObject): {
  rel?: string | undefined;
  target?: HTMLAttributeAnchorTarget | undefined;
} {
  return isExternalUrl(url) ? { rel: "noopener", target: "_blank" } : {};
}
