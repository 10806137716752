import { Translate } from "next-translate";
import { TSelectOption } from "~/components/atoms/form/SelectField";
import { User } from "~/models/user";

export const DEFAULT_USER_STATUS_ROLE: UserStatus["role"] = "normal";

export const FIRESTORE_COLLECTION_NAME_USER_STATUS = "userStatus";

export interface UserStatus {
  concurrentSelfTask: ConcurrentSelfTask;
  createdUnixTime: number;
  createTaskOnCompletion: boolean;
  id: string;
  lastTalkroomId: string | null;
  remindTaskCreation: boolean;
  // Plan to add owner privileges in the future
  role: "admin" | "writer" | "normal" | "owner";
  teamId: string | null;
  updatedUnixTime: number;
}

const concurrentSelfTaskNumbers = [0, 1, 2, 3, 4] as const;
type ConcurrentSelfTask = typeof concurrentSelfTaskNumbers[number];

export function isQaFullEditableRole(userRole: UserStatus["role"]): boolean {
  return ["admin", "writer", "owner"].includes(userRole);
}

export function isAdmin(userRole: UserStatus["role"]): boolean {
  return userRole === "admin";
}

export function isAdminOrOwner(userRole: UserStatus["role"]): boolean {
  return ["admin", "owner"].includes(userRole);
}

export function isWriter(userRole: UserStatus["role"]): boolean {
  return userRole === "writer";
}

export function isTaskFullEditableRole(
  role: UserStatus["role"],
  userId?: User["id"],
  requestWriterUserId?: User["id"]
): boolean {
  if (role !== "writer") {
    return ["admin", "owner"].includes(role);
  }

  return !!(userId && requestWriterUserId && userId === requestWriterUserId);
}

export function isValidUserRole(role: UserStatus["role"]): boolean {
  return ["admin", "writer", "normal", "owner"].includes(role);
}

export function isValidUserRoleForUpdate(role: string): boolean {
  return ["writer", "normal"].includes(role);
}

export function isValidSendMailRole(role: UserStatus["role"]): boolean {
  return ["admin", "writer", "owner"].includes(role);
}

export function isValidReadTaskRole(role: UserStatus["role"]): boolean {
  return ["admin", "writer", "owner"].includes(role);
}

export function isValidCreateBillRole(role: UserStatus["role"]): boolean {
  return isAdminOrOwner(role);
}

export function isValidConcurrentSelfTask(
  value: number
): value is ConcurrentSelfTask {
  return concurrentSelfTaskNumbers.includes(value as ConcurrentSelfTask);
}

export function isValidRoleForQaTargetWord(role: UserStatus["role"]): boolean {
  return ["admin", "writer", "owner"].includes(role);
}

export function isValidRoleForDictionaryItem(
  role: UserStatus["role"]
): boolean {
  return ["admin", "writer", "owner"].includes(role);
}

export function isValidRoleForQaArticleEvaluation(
  role?: UserStatus["role"]
): boolean {
  return ["admin", "writer", "owner"].includes(role as UserStatus["role"]);
}

export function isValidRoleForRegisterTask(role: UserStatus["role"]): boolean {
  return ["admin", "writer", "owner"].includes(role);
}

export function generateConcurrentSelfTaskOptions(): TSelectOption[] {
  return concurrentSelfTaskNumbers.map((value) => {
    const text = value === 0 ? "許可しない" : `同時進行${value}まで許可する`;
    return { text, value: value.toString() };
  });
}

export function getRoleName({
  role,
  t,
}: {
  role: UserStatus["role"] | "";
  t: Translate;
}): string {
  switch (role) {
    case "admin":
      return t("mypage:role-options.admin");
    case "owner":
      return t("mypage:role-options.owner");
    case "normal":
      return t("mypage:role-options.normal");
    case "writer":
      return t("mypage:role-options.writer");
    case "":
      return t("common:text-all");
    default:
      return "";
  }
}
