import { ToastContainer as DefaultToastContainer } from "react-toastify";
import styled, { keyframes } from "styled-components";
import "react-toastify/dist/ReactToastify.css";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const ToastContainer = styled(DefaultToastContainer)`
  --toastify-toast-width: 360px;

  .Toastify__toast-container {
  }
  .Toastify__toast {
    background: rgba(64, 64, 64, 0.95);
    border-radius: 20px;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    height: 46px;
    min-height: unset;
  }
  .Toastify__toast-body {
    align-items: center;
    display: flex;
    flex: unset;
    justify-content: center;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 100%;
  }

  .fade-in {
    animation: ${fadeIn} 0.6s;
  }

  .fade-out {
    animation: ${fadeOut} 0.6s;
  }
`;

export default ToastContainer;
