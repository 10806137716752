import { Box } from "@chakra-ui/react";
import { colorBlack } from "lib/constants";
import useTranslation from "next-translate/useTranslation";
import React, { VFC, ReactElement } from "react";
import styled from "styled-components";
import ResetCss from "styles/ResetCss";
import SidebarLayout from "~/components/layout/SidebarLayout";
import Footer from "mypage/src/components/modules/footer";
import HeaderSideMenuWrap from "mypage/src/components/modules/header/sideMenuWrap";
import { pagesPath } from "mypage/src/lib/$path";
import { DeviceMode } from ".";

interface Props {
  afterMainContent?: React.ReactNode;
  children: ReactElement;
  deviceMode?: DeviceMode;
  hideFooter: boolean;
  hideHeader: boolean;
  isShowSideMenu: boolean;
  leftSidebar?: React.ReactNode;
  onDeviceSwitcherCallback?: () => void;
  rightSidebar?: React.ReactNode;
  talkRoomId?: string;
}

const MyPageLayoutPresenter: VFC<Props> = ({
  afterMainContent,
  children,
  deviceMode,
  hideFooter,
  hideHeader,
  isShowSideMenu,
  leftSidebar,
  onDeviceSwitcherCallback,
  rightSidebar,
  talkRoomId,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div id="page-top" />
      {!hideHeader && (
        <Container>
          <HeaderSideMenuWrap
            deviceMode={deviceMode}
            isShowSideMenu={isShowSideMenu}
            logoUrl="/img/question-mark.svg"
            mainTitle={t("mypage:header.title")}
            mainTitleUrl={pagesPath.home.$url()}
            onDeviceSwitcherCallback={onDeviceSwitcherCallback}
            talkRoomId={talkRoomId}
          />
        </Container>
      )}
      {deviceMode ? (
        <Box id="device-wrap" className={deviceMode ?? ""} bgColor="#fff">
          <SidebarLayout leftSidebar={leftSidebar} rightSidebar={rightSidebar}>
            {children}
          </SidebarLayout>
        </Box>
      ) : (
        <SidebarLayout leftSidebar={leftSidebar} rightSidebar={rightSidebar}>
          {children}
        </SidebarLayout>
      )}

      {afterMainContent}
      {!hideFooter && <Footer />}
      <ResetCss />
    </>
  );
};

const Container = styled.div`
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid ${colorBlack[50]};
  display: flex;
  min-height: 48px;
  padding: 4px 0;
`;

export default MyPageLayoutPresenter;
