import { css } from "styled-components";
import {
  colorBlack,
  colorImportant,
  colorPriceRankPrimary,
  colorPrimary,
  colorStatistic,
  colorSupportAd,
} from "~/lib/constants";

// Keep variable names as few characters as possible to reduce css size
export const DefaultTheme = () => css`
  --ad-bg-clr: ${colorSupportAd.background};
  --ad-clr: ${colorSupportAd.text};
  --alert-caution: #fff6e5;
  --alert-danger: #ffeff2;
  --alert-hint: ${colorPrimary[50]};
  --alert-tip: ${colorPrimary[400]};
  --b-bg-clr10: ${colorBlack[10]};
  --b-clr200: ${colorBlack[200]};
  --b-clr300: ${colorBlack[300]};
  --b-clr800: ${colorBlack[800]};
  --b-clr900: ${colorBlack[900]};
  --bdr-clr: ${colorBlack[100]};
  --body-bg-clr: #fff;
  --body-clr: ${colorBlack[600]};
  --box-bdr-clr: ${colorPrimary[300]};
  --cap-clr: ${colorBlack[400]};
  --cont-bg-clr: ${colorBlack[30]};
  --dark-mode-weight: 400;
  --hv-box-bg-clr: ${colorPrimary[50]};
  --imp-clr10: ${colorImportant[10]};
  --md-cmt-bg-clr: #fff;
  --overlay-bg-clr: #fff9;
  --pr-pri200: ${colorPriceRankPrimary[200]};
  --pr-pri500: ${colorPriceRankPrimary[500]};
  --pri-bg-clr10: ${colorPrimary[10]};
  --pri-bg-clr100: ${colorPrimary[100]};
  --pri-bg-clr50: ${colorPrimary[50]};
  --pri-clr500: ${colorPrimary[500]};
  --pri-clr600: ${colorPrimary[600]};
  --sep-bdr-clr: ${colorBlack[30]};
  --sep-outline-bdr-clr: ${colorBlack[50]};
  --stat-reward-clr: ${colorStatistic.reward};
  --sub-txt-clr: ${colorBlack[500]};
  --title-clr: ${colorBlack[700]};
  --toc-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
`;

export const DarkTheme = () => css`
  @media (prefers-color-scheme: dark) {
    --ad-bg-clr: #12203b;
    --ad-clr: #9db5e2;
    --alert-caution: #664200;
    --alert-danger: #b30021;
    --alert-hint: #2b5532;
    --alert-tip: #a2d161;
    --b-bg-clr10: #2f2f2f;
    --b-clr200: #d9d9d9;
    --b-clr300: #e6e6e6;
    --b-clr800: #f8f8f8;
    --b-clr900: #ffffff;
    --bdr-clr: #666666;
    --body-bg-clr: #333333;
    --body-clr: #fff;
    --box-bdr-clr: #555555;
    --cap-clr: #dcdcdc;
    --cont-bg-clr: #262626;
    --dark-mode-weight: 600;
    --hv-box-bg-clr: #404040;
    --imp-clr10: #665733;
    --md-cmt-bg-clr: #404040;
    --overlay-bg-clr: #0009;
    --pr-pri200: #2c5432;
    --pr-pri500: #25daad;
    --pri-bg-clr10: #262626;
    --pri-bg-clr50: #1a1a1a;
    --pri-bg-clr100: #151515;
    --pri-clr500: ${colorPrimary[400]};
    --pri-clr600: ${colorPrimary[400]};
    --sep-bdr-clr: #262626;
    --sep-outline-bdr-clr: #4d4d4d;
    --stat-reward-clr: #d9ca59;
    --sub-txt-clr: #cccccc;
    --title-clr: #f2f2f2;
    --toc-box-shadow: 0px 2px 5px rgba(255, 255, 255, 0.1);
  }
`;

export const TweetDarkTheme = () => css`
  @media (prefers-color-scheme: dark) {
    --tweet-f-clr: #f1f3f4;
    --tweet-bg-clr: black;
    --tweet-bdr: 1px solid #3b4044;
    --tweet-bdr-hv: 1px solid #535a5f;
    --tweet-clr-b: white;
  }
`;
