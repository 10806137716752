// SITE_NAME is duplicated. Please use 'project.siteName'
export const SITE_NAME = "キクシル";
export const ALGOLIA_APP_ID = "DAAXA7Q7OK";

export const REVALIDATE_INTERVAL_SEC = 60 * 60 * 4;

export const REVALIDATE_INTERVAL_10DAY = 60 * 60 * 24 * 10;

export const MYPAGE_MAX_QUESTIONS_LIMIT_PER_PAGE = 10;
export const MYPAGE_MAX_QUESTIONS_LIMIT_PER_PAGE_FOR_ADMIN = 20;
export const MYPAGE_MAX_QUESTIONS_LIST_LIMIT_PER_PAGE = 10;
export const MYPAGE_MAX_TASKS_LIMIT_PER_PAGE = 10;
export const MYPAGE_MAX_TASKS_LIMIT_PER_PAGE_FOR_ADMIN = 20;
export const QUESTION_ANSWER_AUTO_BACKUP_INTERVAL = 10 * 60; // 10min
export const QUESTION_ANSWER_AUTO_SAVE_DELAY = 1000; // 1s
// number of qa to generate at build time
export const QUESTION_ANSWER_GENERATED_NEWEST_LIMIT = 40;
export const QUESTION_ANSWER_GENERATED_POPULAR_LIMIT = 330;
export const MORE_QUESTION_ANSWER_LIMIT = 10;
export const MORE_SAME_TOPIC_QUESTION_ANSWER_LIMIT = 10;
export const HOME_QA_PER_PAGE = 20;
export const SUBTOPIC_QA_PER_PAGE = 30;
export const TOPIC_QA_PER_PAGE = 30;
export const CREATOR_QA_PER_PAGE = 30;
export const CREATOR_USER_PER_PAGE = 30;
export const SEARCH_RESULT_PER_PAGE = 40;
export const UPLOADED_IMAGE_THUMBNAIL_PER_PAGE = 40;

export const QA_TARGET_WORD_UPDATE_DURATION_IN_DAYS = 30;
export const QA_TARGET_WORD_MIN_LENGTH = 2;

// minimum article of creator to allow generate creator page at build time
export const CREATOR_MIN_ARTICLE_TO_GENERATED = 5;

// number of subtopic to generate at build time
export const DICTIONARY_ITEM_GENERATED_LIMIT = 160;
export const DICTIONARY_ITEM_SHOW_MIN_QA_COUNT_LIMIT = 3;
export const DICTIONARY_ITEM_MAX_NUMBER_OF_LAYERS = 4;
export const DICTIONARY_ITEM_MAX_NUMBER_OF_IMAGES = 3;

export const USER_COVER_IMAGE_MIN_WIDTH = 1000;
export const USER_COVER_IMAGE_MIN_HEIGHT = 400;
export const QA_TOPIC_COVER_IMAGE_MIN_WIDTH = 720;
export const QA_TOPIC_COVER_IMAGE_MIN_HEIGHT = 240;
export const DICTIONARY_ITEM_IMAGE_MIN_WIDTH = 24;
export const DICTIONARY_ITEM_IMAGE_MIN_HEIGHT = 24;

export const GOT_TIMEOUT = 3000;

export const DEFAULT_LOCALE = "ja";

export const DEFAULT_MAX_CHARACTER_FOR_REWARD = 2000;

export const LATEST_BASIC_BUSINESS_CONSIGNMENT_REVISION = "rev1";

export const INVALID_URL_PARAMS_IN_QA_REGEX =
  /tag=[^(\-2{2})]*-2{2}|affiliate_id|utm_source|utm_medium|utm_campaign|utm_term|utm_content/gi;

// setup project id and domain regex in order to detect project id by domain
// the regex includes both production domain and prefix of preview domain
export const PROJECT_IDS_REGEX = {
  "blog.sugowish.com": "blog.sugowish.com|blog-sugowish-com",
  "faq.price-sale.com": "faq.price-sale|faq-price-sale|game-question",
  "galaxy-faq.price-rank.com": "galaxy-faq.price-rank|galaxy-faq-price-rank",
  "kikushiru.com": "kikushiru",
  "mining.price-rank.com": "mining.price-rank.com|mining-price-rank-com",
  "price-rank.dev": "price-rank.dev|price-rank-dev|team.morizyun",
};

export const KIKUSHIRU_MYPAGE_HOST = "mypage.kikushiru.com";
export const MYPAGE_URLS = {
  // add more option in future
  // "faq.price-sale.com": "https://mypage.game-question.com",
  "kikushiru.com": "https://mypage.kikushiru.com",
};

export const CONTENT_MAX_WIDTH = 720;

export const FIRESTORE_CACHE_DEFAULT_MILL_SEC = 10 * 60 * 1000; // mill minutes
export const FIRESTORE_CACHE_REFRESH_MIN_MILL_SEC = 5 * 1000; // mill seconds (We want to use the same data during one rendering, so we keep it for a short period of time)

/* eslint-disable sort-keys */
export const breakPoint = {
  nr: "320px",
  xs: "375px",
  sm: "640px",
  md: "768px",
  lg: "1300px",
};

export const colorBlack = {
  10: "#FCFCFC",
  30: "#F3F3F3",
  50: "#E9E9E9",
  100: "#D4D4D4",
  200: "#BEBEBE",
  300: "#A9A9A9",
  400: "#6F6F6F",
  500: "#7F7F7F",
  600: "#404040",
  700: "#222222",
  800: "#212121",
  900: "#000000",
};

export const colorImportant = {
  10: "#F8F6F1",
  50: "#FCDCE2",
  100: "#FAB9C4",
  200: "#F896A7",
  300: "#F9B7B6",
  400: "#F44F6E",
  500: "#F37873",
  600: "#B6213D",
  700: "#791628",
  800: "#3C0B14",
  900: "#000000",
};

export const colorPriceRankPrimary = {
  200: "#D5E9D8",
  500: "#168468",
};

export const colorPrimary = {
  10: "#F3F9F4",
  50: "#F3F9F4",
  100: "#C9DFDA",
  200: "#9CC8BD",
  300: "#CFE6AF",
  400: "#B5DA82",
  500: "#57821b",
  600: "#537C1A",
};

export const colorPricePrimary = "#B12704";

export const bgColorPriceLabel = {
  new: "#ECD7E8",
  used: "#D5DAEF",
  junk: "#EFE7D5",
};

export const colorPriceLabel = {
  new: "#A2368A",
  used: "#2B48AD",
  junk: "#AD892B",
};

export const colorStatistic = {
  pv: "#00B192",
  reward: "#AA9A27",
};

export const colorSupportAd = {
  background: "#E6F2FE",
  text: "#6087D0",
};

//============================================================================
// Can not use isProductionEnv from "shared/lib/utils/environment", an error will occur when running yarn test
// Get the environment from the Firebase configuration
// (NODE_ENV is often set to production)
const isProductionEnv =
  !!process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN?.includes("prod");

// If you want to try kikushiru-api in your local environment, please change the following URL to http://localhost:3012
// If you want to confirm the preview url of kikushiru-api, set NEXT_PUBLIC_KIKUSHIRU_API_SITE_URL in Vercel environment variables
export const KIKUSHIRU_API_SITE_URL = isProductionEnv
  ? "https://api.kikushiru.com"
  : process.env.NEXT_PUBLIC_KIKUSHIRU_API_SITE_URL ||
    "https://api-stg.kikushiru.com";
export const KIKUSHIRU_BASE_URL = "https://kikushiru.com";
// If you want to try og.price-sale in your local environment, please change the following URL to http://localhost:3006
export const OG_IMAGE_SITE_URL = isProductionEnv
  ? "https://og.price-sale.com"
  : process.env.NEXT_PUBLIC_OG_IMAGE_SITE_URL || "https://og.price-sale.com";
// If you use the Rails app, please change the following URL to http://localhost:3000/graphql
export const PR_GRAPHQL_API = isProductionEnv
  ? "https://g.price-sale.com/graphql"
  : "https://stg.price-rank.com/graphql";
export const PR_GRAPHQL_API_BACKUP = isProductionEnv
  ? "https://price-rank.com/graphql"
  : "https://stg.price-rank.com/graphql";
// If you use the Rails app, please change the following URL to http://localhost:3000/api/v1/worker/kikushiru/question_answer/access_detail_urls
export const PRICE_RANK_ACCESS_QA_API_URL = isProductionEnv
  ? "https://price-rank.com/api/v1/worker/kikushiru/question_answer/access_detail_urls"
  : "https://stg.price-rank.com/api/v1/worker/kikushiru/question_answer/access_detail_urls";
// mail from address for contactable mail
export const KIKUSHIRU_CONTACT_MAIL_FROM = "contact@kikushiru.com";
