import { doc, setDoc } from "firebase/firestore";
import { firestore } from "~/iterators/firestore/firestore";
import { retrieveUser } from "~/iterators/firestore/users/retrieveUser";
import { getFirebaseUserForCreate } from "~/iterators/mappers/toFirebase/users/user";
import {
  FIRESTORE_COLLECTION_NAME_USER,
  FIRESTORE_COLLECTION_NAME_USER_PRIVATE,
  User,
} from "~/models/user";

export async function createUser({
  email,
  imageUrl,
  name,
  originalImageUrl,
  userId,
}: {
  email: string | undefined;
  imageUrl: string | undefined;
  name: string;
  originalImageUrl: string | undefined;
  userId: string;
}): Promise<User> {
  await setDoc(doc(firestore, FIRESTORE_COLLECTION_NAME_USER, userId), {
    id: userId,
    ...getFirebaseUserForCreate({ imageUrl, name }),
  });

  if (email || originalImageUrl) {
    await setDoc(
      doc(
        firestore,
        FIRESTORE_COLLECTION_NAME_USER,
        userId,
        FIRESTORE_COLLECTION_NAME_USER_PRIVATE,
        userId
      ),
      { email, originalImageUrl: originalImageUrl || "" }
    );
  }

  return retrieveUser({ userId }) as Promise<User>;
}
