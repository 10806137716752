import { staticPath } from "faqSrc/lib/$path";
import React, { VFC } from "react";
import styled from "styled-components";
import Image from "components/atoms/Image";
import { colorPrimary } from "~/lib/constants";

type SquareButtonVariant = "home" | "talk" | "back";

interface SquareButtonProps {
  text?: string;
  variant: SquareButtonVariant;
}

const iconMap: { [key in SquareButtonVariant]: string } = {
  back: staticPath.img.green_arrow_left_svg,
  home: staticPath.img.icon_mypage_home_green_svg,
  talk: staticPath.img.icon_talk_green_svg,
};

const textMap: { [key in SquareButtonVariant]: string } = {
  back: "戻る",
  home: "ホーム",
  talk: "トーク",
};

const SquareButton: VFC<SquareButtonProps> = ({
  text: overrideText,
  variant,
}) => {
  const text = overrideText || textMap[variant];

  return (
    <Button>
      <ImageWrap>
        <Image
          src={iconMap[variant]}
          alt={text}
          width={16}
          height={16}
          layout="responsive"
          disableHover
        />
      </ImageWrap>
      <Text>{text}</Text>
    </Button>
  );
};

const Text = styled.span`
  color: ${colorPrimary[500]};
  display: block;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
`;

const Button = styled.div`
  align-items: center;
  background: ${colorPrimary[50]};
  border-radius: 4px;
  cursor: pointer;
  display: grid;
  height: 40px;
  gap: 2px;
  padding: 4px 0;
  text-align: center;
  width: 40px;
  /* Add transparent border to prevent content shift on hover */
  border: solid 1px transparent;

  &:hover {
    border: solid 1px ${colorPrimary[500]};

    & > ${Text} {
      text-decoration: underline;
    }
  }
`;

const ImageWrap = styled.div`
  height: 16px;
  margin: 0 auto;
  width: 16px;
`;

export default SquareButton;
