import {
  QueryDocumentSnapshot,
  DocumentData,
  SnapshotOptions,
  PartialWithFieldValue,
  QuerySnapshot,
} from "firebase/firestore";
import { validateUser } from "~/iterators/mappers/validator/user";
import { extractNonUndefined } from "~/lib/utils/objectHelper";
import { User } from "~/models/user";
import {
  isValidArticleEvaluationBonus,
  UserAgreement,
} from "~/models/user/agreement";
import { UserDailyQaSummary } from "~/models/user/dailyQaSummary";
import { UserManagement } from "~/models/user/management";
import { UserPrivate } from "~/models/user/private";

export const userConverter = {
  fromFirestore(
    snapshot: FirebaseFirestore.DocumentSnapshot | QueryDocumentSnapshot,
    options?: SnapshotOptions
  ): User {
    const data = options ? snapshot.data(options) : snapshot.data();
    const user = {
      articleCVAverageLast30Days: data?.articleCVAverageLast30Days || 0,
      articleCount: data?.articleCount || 0,
      articleFbHelpfulAverageLast30Days:
        data?.articleFbHelpfulAverageLast30Days || 0,
      articleFbHelpfulCount: data?.articleFbHelpfulCount || 0,
      articleFbHelpfulCountLast30Days:
        data?.articleFbHelpfulCountLast30Days || 0,
      articlePVAverageLast30Days: data?.articlePVAverageLast30Days || 0,
      articlePageViewCount: data?.articlePageViewCount || 0,
      articlePageViewCountLast30Days: data?.articlePageViewCountLast30Days || 0,
      articleTextCountAverage: data?.articleTextCountAverage || 0,
      blogUrl: data?.blogUrl || null,
      coverImageUrl: data?.coverImageUrl || null,
      createdUnixTime: data?.createdUnixTime,
      description: data?.description || null,
      id: snapshot.id,
      imageUrl: data?.imageUrl || null,
      instagramUrl: data?.instagramUrl || null,
      jobTitle: data?.jobTitle || null,
      name: data?.name,
      portfolioUrl1: data?.portfolioUrl1 || null,
      portfolioUrl2: data?.portfolioUrl2 || null,
      specialtyIds: data?.specialtyIds || null,
      supportAdClickCount: data?.supportAdClickCount || 0,
      twitterUrl: data?.twitterUrl || null,
      updatedUnixTime: data?.updatedUnixTime,
      visible: data?.visible !== false,
      youtubeUrl: data?.youtubeUrl || null,
    };

    validateUser(user);

    return user;
  },
  toFirestore(user: PartialWithFieldValue<User>): DocumentData {
    return extractNonUndefined(user);
  },
};

export const userPrivateConverter = {
  fromFirestore(
    snapshot: FirebaseFirestore.DocumentSnapshot | QueryDocumentSnapshot,
    options?: SnapshotOptions
  ): UserPrivate {
    const data = options ? snapshot.data(options) : snapshot.data();
    const crowdsourcingAccountUrls = data?.crowdsourcingAccountUrls;

    return {
      averageGamePlayStatus: data?.averageGamePlayStatus || null,
      billAddress: data?.billAddress || null,
      billName: data?.billName || null,
      crowdsourcingAccountUrls: crowdsourcingAccountUrls
        ? {
            coconala: crowdsourcingAccountUrls.coconala ?? null,
            crowdworks: crowdsourcingAccountUrls.crowdworks ?? null,
            lancers: crowdsourcingAccountUrls.lancers ?? null,
          }
        : null,
      desiredUnitPrice: data?.desiredUnitPrice || null,
      email: data?.email || null,
      favoriteGameComment: data?.favoriteGameComment || null,
      id: snapshot.id || null,
      minimumUnitPrice: data?.minimumUnitPrice || null,
      paymentMethod: data?.paymentMethod || null,
      rewardBonusType: data?.rewardBonusType || null,
      rewardLast30Days: data?.rewardLast30Days || 0,
      rewardStartNumber: data?.rewardStartNumber || null,
      rewardTargetArticleNumber: data?.rewardTargetArticleNumber || null,
      rewardUnit: data?.rewardUnit || null,
      rewardUpperLimit: data?.rewardUpperLimit || null,
      skills: data?.skills || null,
      transferAccountBankName: data?.transferAccountBankName || null,
      transferAccountBranchName: data?.transferAccountBranchName || null,
      transferAccountName: data?.transferAccountName || null,
      transferAccountNumber: data?.transferAccountNumber || null,
      transferAccountType: data?.transferAccountType || null,
      workExperience: data?.workExperience || null,
    };
  },
  toFirestore(userPrivate: PartialWithFieldValue<UserPrivate>): DocumentData {
    return extractNonUndefined(userPrivate);
  },
};

export const userAgreementConverter = {
  fromFirestore(
    snapshot: FirebaseFirestore.DocumentSnapshot | QueryDocumentSnapshot,
    options?: SnapshotOptions
  ): UserAgreement {
    const data = options ? snapshot.data(options) : snapshot.data();
    const defaultArticleEvaluationBonus = data?.defaultArticleEvaluationBonus;

    return {
      defaultArticleEvaluationBonus: isValidArticleEvaluationBonus(
        defaultArticleEvaluationBonus
      )
        ? defaultArticleEvaluationBonus
        : null,
      defaultCharacterUnitPrice: data?.defaultCharacterUnitPrice || null,
      defaultMaxCharacterForReward: data?.defaultMaxCharacterForReward || null,
      id: snapshot.id,
      userId: data?.userId || null,
    };
  },
  toFirestore(
    userAgreement: PartialWithFieldValue<UserAgreement>
  ): DocumentData {
    return extractNonUndefined(userAgreement);
  },
};

export const userDailyQaSummaryConverter = {
  fromFirestore(
    snapshot: FirebaseFirestore.DocumentSnapshot | QueryDocumentSnapshot,
    options?: SnapshotOptions
  ): UserDailyQaSummary {
    const data = options ? snapshot.data(options) : snapshot.data();
    return {
      date: data?.date || null,
      pageViewCount: data?.pageViewCount || 0,
    };
  },
  toFirestore(
    userDailyQaSummary: PartialWithFieldValue<UserDailyQaSummary>
  ): DocumentData {
    return extractNonUndefined(userDailyQaSummary);
  },
};

export const userManagementConverter = {
  fromFirestore(
    snapshot: FirebaseFirestore.DocumentSnapshot | QueryDocumentSnapshot,
    options?: SnapshotOptions
  ): UserManagement {
    const data = options ? snapshot.data(options) : snapshot.data();
    return {
      id: snapshot.id,
      jobEvaluation: data?.jobEvaluation || null,
      jobEvaluationUpdatedUnixTime: data?.jobEvaluationUpdatedUnixTime || null,
      paymentDetail: data?.paymentDetail || null,
      userId: data?.userId || null,
    };
  },
  toFirestore(
    userManagement: PartialWithFieldValue<UserManagement>
  ): DocumentData {
    return extractNonUndefined(userManagement);
  },
};

export function convertToUsers({
  usersSnapshot,
}: {
  usersSnapshot: QuerySnapshot<User>;
}): User[] {
  const users: User[] = [];

  usersSnapshot.forEach((userDoc: QueryDocumentSnapshot<User>) => {
    users.push(userDoc.data());
  });

  return users;
}

export function convertToUserAgreements({
  agreementsSnapshot,
}: {
  agreementsSnapshot:
    | QuerySnapshot<UserAgreement>
    | FirebaseFirestore.QuerySnapshot<UserAgreement>;
}): UserAgreement[] {
  const agreements: UserAgreement[] = [];

  agreementsSnapshot.forEach(
    (
      agreementDoc:
        | QueryDocumentSnapshot<UserAgreement>
        | FirebaseFirestore.QueryDocumentSnapshot<UserAgreement>
    ) => {
      agreements.push(agreementDoc.data());
    }
  );

  return agreements;
}
