import { I18nDictionary } from "next-translate";

// locale loader function is used to config loadLocaleFrom of i18n config
// config localeLoader directly in i18n.js will occur `Critical dependency` warning
export async function localeLoader(
  locale: string | undefined,
  namespace: string
): Promise<I18nDictionary> {
  return import(`mypage/locales/${locale}/${namespace}`)
    .catch(() => {
      // load default translation when current translation not found
      return import(`mypage/locales/ja/${namespace}`);
    })
    .then((m) => m.default);
}
