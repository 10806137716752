import type { OptionalQuery as OptionalQuery0 } from '../pages/home/qa'
import type { OptionalQuery as OptionalQuery1 } from '../pages/login'
import type { OptionalQuery as OptionalQuery2 } from '../pages/tasks/new'
import type { OptionalQuery as OptionalQuery3 } from '../pages/user/registration/thanks'

export const pagesPath = {
  "$404": {
    $url: (url?: { hash?: string }) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  "about_us": {
    "edit": {
      $url: (url?: { hash?: string }) => ({ pathname: '/about-us/edit' as const, hash: url?.hash })
    },
    "preview": {
      $url: (url?: { hash?: string }) => ({ pathname: '/about-us/preview' as const, hash: url?.hash })
    }
  },
  "analytics": {
    $url: (url?: { hash?: string }) => ({ pathname: '/analytics' as const, hash: url?.hash })
  },
  "bill": {
    _billId: (billId: string | number) => ({
      "confirm": {
        $url: (url?: { hash?: string }) => ({ pathname: '/bill/[billId]/confirm' as const, query: { billId }, hash: url?.hash })
      },
      "payment": {
        $url: (url?: { hash?: string }) => ({ pathname: '/bill/[billId]/payment' as const, query: { billId }, hash: url?.hash })
      },
      "print_view": {
        $url: (url?: { hash?: string }) => ({ pathname: '/bill/[billId]/print_view' as const, query: { billId }, hash: url?.hash })
      },
      "view": {
        $url: (url?: { hash?: string }) => ({ pathname: '/bill/[billId]/view' as const, query: { billId }, hash: url?.hash })
      }
    }),
    "list": {
      $url: (url?: { hash?: string }) => ({ pathname: '/bill/list' as const, hash: url?.hash })
    },
    "list_preview": {
      $url: (url?: { hash?: string }) => ({ pathname: '/bill/list_preview' as const, hash: url?.hash })
    },
    "unit_preview": {
      $url: (url?: { hash?: string }) => ({ pathname: '/bill/unit_preview' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/bill' as const, hash: url?.hash })
  },
  "contract": {
    "basicBusinessConsignment": {
      "rev1": {
        "print": {
          $url: (url?: { hash?: string }) => ({ pathname: '/contract/basicBusinessConsignment/rev1/print' as const, hash: url?.hash })
        },
        $url: (url?: { hash?: string }) => ({ pathname: '/contract/basicBusinessConsignment/rev1' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/contract/basicBusinessConsignment' as const, hash: url?.hash })
    }
  },
  "home": {
    "dashboard": {
      $url: (url?: { hash?: string }) => ({ pathname: '/home/dashboard' as const, hash: url?.hash })
    },
    "qa": {
      "latest": {
        $url: (url?: { hash?: string }) => ({ pathname: '/home/qa/latest' as const, hash: url?.hash })
      },
      "pv": {
        $url: (url?: { hash?: string }) => ({ pathname: '/home/qa/pv' as const, hash: url?.hash })
      },
      "rewrite": {
        "config": {
          $url: (url?: { hash?: string }) => ({ pathname: '/home/qa/rewrite/config' as const, hash: url?.hash })
        }
      },
      "useful": {
        $url: (url?: { hash?: string }) => ({ pathname: '/home/qa/useful' as const, hash: url?.hash })
      },
      $url: (url?: { query?: OptionalQuery0, hash?: string }) => ({ pathname: '/home/qa' as const, query: url?.query, hash: url?.hash })
    },
    "question": {
      _questionId: (questionId: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/home/question/[questionId]' as const, query: { questionId }, hash: url?.hash })
      }),
      "inactive": {
        $url: (url?: { hash?: string }) => ({ pathname: '/home/question/inactive' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/home/question' as const, hash: url?.hash })
    },
    "tasks": {
      "done": {
        $url: (url?: { hash?: string }) => ({ pathname: '/home/tasks/done' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/home/tasks' as const, hash: url?.hash })
    },
    "users": {
      "experienced": {
        $url: (url?: { hash?: string }) => ({ pathname: '/home/users/experienced' as const, hash: url?.hash })
      },
      "latest": {
        $url: (url?: { hash?: string }) => ({ pathname: '/home/users/latest' as const, hash: url?.hash })
      },
      "writer": {
        $url: (url?: { hash?: string }) => ({ pathname: '/home/users/writer' as const, hash: url?.hash })
      }
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/home' as const, hash: url?.hash })
  },
  "login": {
    $url: (url?: { query?: OptionalQuery1, hash?: string }) => ({ pathname: '/login' as const, query: url?.query, hash: url?.hash })
  },
  "logout": {
    $url: (url?: { hash?: string }) => ({ pathname: '/logout' as const, hash: url?.hash })
  },
  "management": {
    "sendMail": {
      $url: (url?: { hash?: string }) => ({ pathname: '/management/sendMail' as const, hash: url?.hash })
    }
  },
  "qa": {
    _qaId: (qaId: string | number) => ({
      "edit": {
        "admin": {
          $url: (url?: { hash?: string }) => ({ pathname: '/qa/[qaId]/edit/admin' as const, query: { qaId }, hash: url?.hash })
        },
        $url: (url?: { hash?: string }) => ({ pathname: '/qa/[qaId]/edit' as const, query: { qaId }, hash: url?.hash })
      },
      "preview": {
        $url: (url?: { hash?: string }) => ({ pathname: '/qa/[qaId]/preview' as const, query: { qaId }, hash: url?.hash })
      }
    }),
    "new": {
      $url: (url?: { hash?: string }) => ({ pathname: '/qa/new' as const, hash: url?.hash })
    }
  },
  "specialities": {
    _specialityId: (specialityId: string | number) => ({
      "edit": {
        $url: (url?: { hash?: string }) => ({ pathname: '/specialities/[specialityId]/edit' as const, query: { specialityId }, hash: url?.hash })
      }
    }),
    "new": {
      $url: (url?: { hash?: string }) => ({ pathname: '/specialities/new' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/specialities' as const, hash: url?.hash })
  },
  "subTopics": {
    _subTopicId: (subTopicId: string | number) => ({
      "edit": {
        $url: (url?: { hash?: string }) => ({ pathname: '/subTopics/[subTopicId]/edit' as const, query: { subTopicId }, hash: url?.hash })
      }
    }),
    "new": {
      $url: (url?: { hash?: string }) => ({ pathname: '/subTopics/new' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/subTopics' as const, hash: url?.hash })
  },
  "talkRooms": {
    _talkRoomId: (talkRoomId: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/talkRooms/[talkRoomId]' as const, query: { talkRoomId }, hash: url?.hash })
    })
  },
  "tasks": {
    _taskId: (taskId: string | number) => ({
      "confirm": {
        $url: (url?: { hash?: string }) => ({ pathname: '/tasks/[taskId]/confirm' as const, query: { taskId }, hash: url?.hash })
      },
      "deadlineExtension": {
        $url: (url?: { hash?: string }) => ({ pathname: '/tasks/[taskId]/deadlineExtension' as const, query: { taskId }, hash: url?.hash })
      },
      "edit": {
        $url: (url?: { hash?: string }) => ({ pathname: '/tasks/[taskId]/edit' as const, query: { taskId }, hash: url?.hash })
      },
      "endReport": {
        "thanks": {
          $url: (url?: { hash?: string }) => ({ pathname: '/tasks/[taskId]/endReport/thanks' as const, query: { taskId }, hash: url?.hash })
        },
        $url: (url?: { hash?: string }) => ({ pathname: '/tasks/[taskId]/endReport' as const, query: { taskId }, hash: url?.hash })
      },
      "request": {
        $url: (url?: { hash?: string }) => ({ pathname: '/tasks/[taskId]/request' as const, query: { taskId }, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/tasks/[taskId]' as const, query: { taskId }, hash: url?.hash })
    }),
    "new": {
      $url: (url?: { query?: OptionalQuery2, hash?: string }) => ({ pathname: '/tasks/new' as const, query: url?.query, hash: url?.hash })
    },
    "sendRequest": {
      "thanks": {
        $url: (url?: { hash?: string }) => ({ pathname: '/tasks/sendRequest/thanks' as const, hash: url?.hash })
      }
    },
    "writerCreation": {
      "messageToSupport": {
        $url: (url?: { hash?: string }) => ({ pathname: '/tasks/writerCreation/messageToSupport' as const, hash: url?.hash })
      },
      "nextTask": {
        $url: (url?: { hash?: string }) => ({ pathname: '/tasks/writerCreation/nextTask' as const, hash: url?.hash })
      },
      "topic": {
        $url: (url?: { hash?: string }) => ({ pathname: '/tasks/writerCreation/topic' as const, hash: url?.hash })
      }
    }
  },
  "topics": {
    _topicId: (topicId: string | number) => ({
      "edit": {
        $url: (url?: { hash?: string }) => ({ pathname: '/topics/[topicId]/edit' as const, query: { topicId }, hash: url?.hash })
      }
    }),
    "new": {
      $url: (url?: { hash?: string }) => ({ pathname: '/topics/new' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/topics' as const, hash: url?.hash })
  },
  "user": {
    _id: (id: string | number) => ({
      "preview": {
        $url: (url?: { hash?: string }) => ({ pathname: '/user/[id]/preview' as const, query: { id }, hash: url?.hash })
      }
    }),
    "bill": {
      "edit": {
        $url: (url?: { hash?: string }) => ({ pathname: '/user/bill/edit' as const, hash: url?.hash })
      }
    },
    "edit": {
      "step1": {
        $url: (url?: { hash?: string }) => ({ pathname: '/user/edit/step1' as const, hash: url?.hash })
      },
      "step2": {
        $url: (url?: { hash?: string }) => ({ pathname: '/user/edit/step2' as const, hash: url?.hash })
      },
      "step3": {
        $url: (url?: { hash?: string }) => ({ pathname: '/user/edit/step3' as const, hash: url?.hash })
      }
    },
    "registration": {
      "etc": {
        $url: (url?: { hash?: string }) => ({ pathname: '/user/registration/etc' as const, hash: url?.hash })
      },
      "exp_writer": {
        $url: (url?: { hash?: string }) => ({ pathname: '/user/registration/exp_writer' as const, hash: url?.hash })
      },
      "game_writer": {
        $url: (url?: { hash?: string }) => ({ pathname: '/user/registration/game_writer' as const, hash: url?.hash })
      },
      "reason": {
        $url: (url?: { hash?: string }) => ({ pathname: '/user/registration/reason' as const, hash: url?.hash })
      },
      "thanks": {
        $url: (url?: { query?: OptionalQuery3, hash?: string }) => ({ pathname: '/user/registration/thanks' as const, query: url?.query, hash: url?.hash })
      },
      "writer": {
        $url: (url?: { hash?: string }) => ({ pathname: '/user/registration/writer' as const, hash: url?.hash })
      }
    }
  },
  $url: (url?: { hash?: string }) => ({ pathname: '/' as const, hash: url?.hash })
}

export type PagesPath = typeof pagesPath

export const staticPath = {
  favicon_ico: '/favicon.ico',
  img: {
    add_column_svg: '/img/add-column.svg',
    add_row_svg: '/img/add-row.svg',
    cancel_svg: '/img/cancel.svg',
    check_mark_svg: '/img/check-mark.svg',
    chevron_down_black_svg: '/img/chevron-down-black.svg',
    chevron_down_light_green_svg: '/img/chevron-down-light-green.svg',
    chevron_down_svg: '/img/chevron-down.svg',
    chevron_right_gray_svg: '/img/chevron-right-gray.svg',
    chevron_right_svg: '/img/chevron-right.svg',
    counting_svg: '/img/counting.svg',
    double_chevron_down_svg: '/img/double-chevron-down.svg',
    emoji: {
      $1f389_svg: '/img/emoji/1f389.svg',
      $1f4aa_svg: '/img/emoji/1f4aa.svg',
      $1f601_svg: '/img/emoji/1f601.svg'
    },
    end_report: {
      brainstorm_think_png: '/img/end-report/brainstorm-think.png',
      design_tool_png: '/img/end-report/design-tool.png',
      editor_png: '/img/end-report/editor.png',
      game_controller_png: '/img/end-report/game-controller.png',
      girl_png: '/img/end-report/girl.png',
      thinking_face_png: '/img/end-report/thinking-face.png',
      writer_png: '/img/end-report/writer.png'
    },
    flags: {
      jp_svg: '/img/flags/jp.svg',
      us_svg: '/img/flags/us.svg',
      vn_svg: '/img/flags/vn.svg'
    },
    gaming_svg: '/img/gaming.svg',
    green_arrow_left_svg: '/img/green-arrow-left.svg',
    green_arrow_right_light_svg: '/img/green-arrow-right-light.svg',
    green_arrow_right_svg: '/img/green-arrow-right.svg',
    icon_add_svg: '/img/icon-add.svg',
    icon_approve_svg: '/img/icon-approve.svg',
    icon_arrow_blue_svg: '/img/icon-arrow-blue.svg',
    icon_arrow_down_gray_svg: '/img/icon-arrow-down-gray.svg',
    icon_arrow_left_svg: '/img/icon-arrow-left.svg',
    icon_arrow_right_gray_svg: '/img/icon-arrow-right-gray.svg',
    icon_arrow_right_red_svg: '/img/icon-arrow-right-red.svg',
    icon_arrow_up_svg: '/img/icon-arrow-up.svg',
    icon_article_edit_green_svg: '/img/icon-article-edit-green.svg',
    icon_article_edit_white_svg: '/img/icon-article-edit-white.svg',
    icon_article_edit_svg: '/img/icon-article-edit.svg',
    icon_article_gray_svg: '/img/icon-article-gray.svg',
    icon_article_svg: '/img/icon-article.svg',
    icon_attention_svg: '/img/icon-attention.svg',
    icon_badge_reward_svg: '/img/icon-badge-reward.svg',
    icon_book_svg: '/img/icon-book.svg',
    icon_caret_down_svg: '/img/icon-caret-down.svg',
    icon_caret_right_svg: '/img/icon-caret-right.svg',
    icon_chart_svg: '/img/icon-chart.svg',
    icon_check_gray_svg: '/img/icon-check-gray.svg',
    icon_check_green_svg: '/img/icon-check-green.svg',
    icon_check_list_svg: '/img/icon-check-list.svg',
    icon_clipboard_black_svg: '/img/icon-clipboard-black.svg',
    icon_clipboard_green_svg: '/img/icon-clipboard-green.svg',
    icon_clipboard_svg: '/img/icon-clipboard.svg',
    icon_clock_svg: '/img/icon-clock.svg',
    icon_cloud_green_svg: '/img/icon-cloud-green.svg',
    icon_cloud_svg: '/img/icon-cloud.svg',
    icon_comment_green_svg: '/img/icon-comment-green.svg',
    icon_comment_square_svg: '/img/icon-comment-square.svg',
    icon_comment_white_svg: '/img/icon-comment-white.svg',
    icon_comment_svg: '/img/icon-comment.svg',
    icon_conversation_svg: '/img/icon-conversation.svg',
    icon_cross_svg: '/img/icon-cross.svg',
    icon_deadline_white_svg: '/img/icon-deadline-white.svg',
    icon_deadline_svg: '/img/icon-deadline.svg',
    icon_dictionary_black_svg: '/img/icon-dictionary-black.svg',
    icon_dictionary_svg: '/img/icon-dictionary.svg',
    icon_double_arrow_down_gray_svg: '/img/icon-double-arrow-down-gray.svg',
    icon_double_arrow_down_svg: '/img/icon-double-arrow-down.svg',
    icon_double_arrow_right_svg: '/img/icon-double-arrow-right.svg',
    icon_download_svg: '/img/icon-download.svg',
    icon_edit_svg: '/img/icon-edit.svg',
    icon_error_svg: '/img/icon-error.svg',
    icon_exclamation_svg: '/img/icon-exclamation.svg',
    icon_external_link_gray_svg: '/img/icon-external-link-gray.svg',
    icon_eye_green_svg: '/img/icon-eye-green.svg',
    icon_eye_slash_svg: '/img/icon-eye-slash.svg',
    icon_eye_white_svg: '/img/icon-eye-white.svg',
    icon_eye_svg: '/img/icon-eye.svg',
    icon_feather_svg: '/img/icon-feather.svg',
    icon_filter_white_svg: '/img/icon-filter-white.svg',
    icon_filter_svg: '/img/icon-filter.svg',
    icon_flag_svg: '/img/icon-flag.svg',
    icon_google_color_svg: '/img/icon-google-color.svg',
    icon_google_svg: '/img/icon-google.svg',
    icon_graph_svg: '/img/icon-graph.svg',
    icon_handshake_svg: '/img/icon-handshake.svg',
    icon_heart_outlined_svg: '/img/icon-heart-outlined.svg',
    icon_heart_pink_svg: '/img/icon-heart-pink.svg',
    icon_heart_separate_svg: '/img/icon-heart-separate.svg',
    icon_help_gray_svg: '/img/icon-help-gray.svg',
    icon_help_web_svg: '/img/icon-help-web.svg',
    icon_help_svg: '/img/icon-help.svg',
    icon_home_svg: '/img/icon-home.svg',
    icon_idea_svg: '/img/icon-idea.svg',
    icon_interview_svg: '/img/icon-interview.svg',
    icon_invoice_svg: '/img/icon-invoice.svg',
    icon_keyword_svg: '/img/icon-keyword.svg',
    icon_light_right_arrow_svg: '/img/icon-light-right-arrow.svg',
    icon_light_svg: '/img/icon-light.svg',
    icon_lint_bot_svg: '/img/icon-lint-bot.svg',
    icon_lock_svg: '/img/icon-lock.svg',
    icon_mail_green_svg: '/img/icon-mail-green.svg',
    icon_mail_send_svg: '/img/icon-mail-send.svg',
    icon_mail_white_svg: '/img/icon-mail-white.svg',
    icon_management_svg: '/img/icon-management.svg',
    icon_medal_black_svg: '/img/icon-medal-black.svg',
    icon_medal_svg: '/img/icon-medal.svg',
    icon_money_svg: '/img/icon-money.svg',
    icon_more_article_svg: '/img/icon-more-article.svg',
    icon_mypage_home_green_svg: '/img/icon-mypage-home-green.svg',
    icon_mypage_home_svg: '/img/icon-mypage-home.svg',
    icon_no_image_svg: '/img/icon-no-image.svg',
    icon_note_svg: '/img/icon-note.svg',
    icon_open_sign_svg: '/img/icon-open-sign.svg',
    icon_paper_airplane_svg: '/img/icon-paper-airplane.svg',
    icon_paper_and_pen_svg: '/img/icon-paper-and-pen.svg',
    icon_please_svg: '/img/icon-please.svg',
    icon_point_more_article_svg: '/img/icon-point-more-article.svg',
    icon_preview_svg: '/img/icon-preview.svg',
    icon_price_tag_svg: '/img/icon-price-tag.svg',
    icon_question_square_svg: '/img/icon-question-square.svg',
    icon_rectangle_svg: '/img/icon-rectangle.svg',
    icon_reload_svg: '/img/icon-reload.svg',
    icon_remove_green_svg: '/img/icon-remove-green.svg',
    icon_remove_svg: '/img/icon-remove.svg',
    icon_rewrite_svg: '/img/icon-rewrite.svg',
    icon_rise_svg: '/img/icon-rise.svg',
    icon_rubber_green_svg: '/img/icon-rubber-green.svg',
    icon_rubber_red_svg: '/img/icon-rubber-red.svg',
    icon_rubber_svg: '/img/icon-rubber.svg',
    icon_same_topic_qa_svg: '/img/icon-same-topic-qa.svg',
    icon_search_white_svg: '/img/icon-search-white.svg',
    icon_search_word_svg: '/img/icon-search-word.svg',
    icon_search_svg: '/img/icon-search.svg',
    icon_secret_svg: '/img/icon-secret.svg',
    icon_settings_green_svg: '/img/icon-settings-green.svg',
    icon_settings_svg: '/img/icon-settings.svg',
    icon_single_comment_svg: '/img/icon-single-comment.svg',
    icon_smile_outlined_svg: '/img/icon-smile-outlined.svg',
    icon_speedometer_svg: '/img/icon-speedometer.svg',
    icon_square_dotted_svg: '/img/icon-square-dotted.svg',
    icon_study_svg: '/img/icon-study.svg',
    icon_sub_topic_svg: '/img/icon-sub-topic.svg',
    icon_sync_svg: '/img/icon-sync.svg',
    icon_table_svg: '/img/icon-table.svg',
    icon_talk_gray_svg: '/img/icon-talk-gray.svg',
    icon_talk_green_svg: '/img/icon-talk-green.svg',
    icon_talk_svg: '/img/icon-talk.svg',
    icon_thumbs_up_svg: '/img/icon-thumbs-up.svg',
    icon_thumbsdown_active_svg: '/img/icon-thumbsdown-active.svg',
    icon_thumbsdown_inactive_svg: '/img/icon-thumbsdown-inactive.svg',
    icon_thumbsdown_outline_svg: '/img/icon-thumbsdown-outline.svg',
    icon_thumbsup_active_svg: '/img/icon-thumbsup-active.svg',
    icon_thumbsup_inactive_svg: '/img/icon-thumbsup-inactive.svg',
    icon_thumbsup_outline_svg: '/img/icon-thumbsup-outline.svg',
    icon_topic_svg: '/img/icon-topic.svg',
    icon_trash_svg: '/img/icon-trash.svg',
    icon_trophy_svg: '/img/icon-trophy.svg',
    icon_upload_svg: '/img/icon-upload.svg',
    icon_user_placeholder_svg: '/img/icon-user-placeholder.svg',
    icon_user_svg: '/img/icon-user.svg',
    icon_visitor_svg: '/img/icon-visitor.svg',
    icon_warning_svg: '/img/icon-warning.svg',
    icon_write_svg: '/img/icon-write.svg',
    key_hole_svg: '/img/key-hole.svg',
    left_arrow_icon_gray_svg: '/img/left-arrow-icon-gray.svg',
    left_arrow_icon_svg: '/img/left-arrow-icon.svg',
    many_stars_png: '/img/many-stars.png',
    online_connection_png: '/img/online-connection.png',
    online_game_svg: '/img/online-game.svg',
    pages: {
      about_us: {
        about_us_cover_png: '/img/pages/about-us/about-us-cover.png'
      },
      for_writer: {
        appreciation_png: '/img/pages/for-writer/appreciation.png',
        team_collaboration_png: '/img/pages/for-writer/team_collaboration.png',
        traveling_png: '/img/pages/for-writer/traveling.png',
        well_done_png: '/img/pages/for-writer/well_done.png'
      },
      how_to_review: {
        write_a_comment_png: '/img/pages/how-to-review/write-a-comment.png'
      }
    },
    please_svg: '/img/please.svg',
    privacy_cover_png: '/img/privacy-cover.png',
    profile_cover_png: '/img/profile-cover.png',
    project_icon: {
      king_svg: '/img/project-icon/king.svg'
    },
    question_mark_fill_png: '/img/question-mark-fill.png',
    question_mark_svg: '/img/question-mark.svg',
    question_mark2_svg: '/img/question-mark2.svg',
    review_step: {
      clipboard_gray_svg: '/img/review-step/clipboard-gray.svg',
      clipboard_svg: '/img/review-step/clipboard.svg',
      correction_gray_svg: '/img/review-step/correction-gray.svg',
      correction_svg: '/img/review-step/correction.svg',
      great_buddha_gray_svg: '/img/review-step/great-buddha-gray.svg',
      great_buddha_svg: '/img/review-step/great-buddha.svg',
      review_gray_svg: '/img/review-step/review-gray.svg',
      review_svg: '/img/review-step/review.svg',
      trusted_gray_svg: '/img/review-step/trusted-gray.svg',
      trusted_svg: '/img/review-step/trusted.svg'
    },
    site_icon: {
      android_icon_144x144_png: '/img/site-icon/android-icon-144x144.png',
      android_icon_192x192_png: '/img/site-icon/android-icon-192x192.png',
      android_icon_36x36_png: '/img/site-icon/android-icon-36x36.png',
      android_icon_48x48_png: '/img/site-icon/android-icon-48x48.png',
      android_icon_72x72_png: '/img/site-icon/android-icon-72x72.png',
      android_icon_96x96_png: '/img/site-icon/android-icon-96x96.png',
      apple_icon_114x114_png: '/img/site-icon/apple-icon-114x114.png',
      apple_icon_120x120_png: '/img/site-icon/apple-icon-120x120.png',
      apple_icon_144x144_png: '/img/site-icon/apple-icon-144x144.png',
      apple_icon_152x152_png: '/img/site-icon/apple-icon-152x152.png',
      apple_icon_180x180_png: '/img/site-icon/apple-icon-180x180.png',
      apple_icon_57x57_png: '/img/site-icon/apple-icon-57x57.png',
      apple_icon_60x60_png: '/img/site-icon/apple-icon-60x60.png',
      apple_icon_72x72_png: '/img/site-icon/apple-icon-72x72.png',
      apple_icon_76x76_png: '/img/site-icon/apple-icon-76x76.png',
      apple_icon_precomposed_png: '/img/site-icon/apple-icon-precomposed.png',
      apple_icon_png: '/img/site-icon/apple-icon.png',
      favicon_16x16_png: '/img/site-icon/favicon-16x16.png',
      favicon_32x32_png: '/img/site-icon/favicon-32x32.png',
      favicon_96x96_png: '/img/site-icon/favicon-96x96.png',
      kikushiru_og_image_png: '/img/site-icon/kikushiru-og-image.png',
      ms_icon_144x144_png: '/img/site-icon/ms-icon-144x144.png',
      ms_icon_150x150_png: '/img/site-icon/ms-icon-150x150.png',
      ms_icon_310x310_png: '/img/site-icon/ms-icon-310x310.png',
      ms_icon_70x70_png: '/img/site-icon/ms-icon-70x70.png'
    },
    sns: {
      line_white_svg: '/img/sns/line-white.svg',
      line_svg: '/img/sns/line.svg',
      twitter_white_svg: '/img/sns/twitter-white.svg',
      twitter_svg: '/img/sns/twitter.svg'
    },
    social: {
      blog_svg: '/img/social/blog.svg',
      instagram_svg: '/img/social/instagram.svg',
      link_svg: '/img/social/link.svg',
      twitter_svg: '/img/social/twitter.svg',
      youtube_svg: '/img/social/youtube.svg'
    },
    sorry_png: '/img/sorry.png',
    static_tweet: {
      icon_chevron_svg: '/img/static-tweet/icon-chevron.svg',
      icon_heart_hover_svg: '/img/static-tweet/icon-heart-hover.svg',
      icon_heart_svg: '/img/static-tweet/icon-heart.svg',
      icon_profile_svg: '/img/static-tweet/icon-profile.svg',
      icon_reply_hover_svg: '/img/static-tweet/icon-reply-hover.svg',
      icon_reply_svg: '/img/static-tweet/icon-reply.svg',
      icon_twitter_svg: '/img/static-tweet/icon-twitter.svg'
    },
    support_ads: {
      life_media_png: '/img/support-ads/life_media.png',
      poiple_png: '/img/support-ads/poiple.png',
      rakuten_insight_png: '/img/support-ads/rakuten_insight.png'
    },
    switcher_pc_svg: '/img/switcher-pc.svg',
    switcher_sp_svg: '/img/switcher-sp.svg',
    tag_svg: '/img/tag.svg',
    teacher_svg: '/img/teacher.svg',
    terms_cover_png: '/img/terms-cover.png',
    web_site_svg: '/img/web-site.svg',
    white_chevron_right_svg: '/img/white-chevron-right.svg'
  },
  manifest_json: '/manifest.json',
  robots_txt: '/robots.txt'
} as const

export type StaticPath = typeof staticPath
