import { PR_GRAPHQL_API } from "~/lib/constants";

// Whether to build with CI(Buildkite), pre-commit, or Vercel Preview
export function isPreBuild(): boolean {
  return !!(
    process.env.NODE_ENV === "production" &&
    (!process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN?.includes("prod") ||
      PR_GRAPHQL_API?.includes("stg."))
  );
}

// Get the environment from the Firebase configuration
// (NODE_ENV is often set to production)
export function isProductionEnv(): boolean {
  return !!process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN?.includes("prod");
}
