import { NextPageWithLayout } from "next";
import appWithI18n from "next-translate/appWithI18n";
import { AppProps } from "next/app";
import * as React from "react";
import i18nConfig from "mypage/i18n";
import AppLayout from "mypage/src/components/layout/AppLayout";
import { localeLoader } from "mypage/src/lib/i18n/localeLoader";

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout =
    Component.getLayout ?? ((page) => <AppLayout>{page}</AppLayout>);

  return getLayout(<Component {...pageProps} />);
}

// cast App as any to resolve data type mismatch
export default appWithI18n(App as any, {
  ...i18nConfig,
  loadLocaleFrom: localeLoader,
  // Set to false if you want to load all the namespaces on _app.js getInitialProps
  skipInitialProps: true,
});
