import React, { FC, ReactElement } from "react";
import MyPageLayoutPresenter from "mypage/src/components/layout/Presenter";

export type DeviceMode = "pc" | "sp";
interface Props {
  afterMainContent?: React.ReactNode;
  children: ReactElement;
  deviceMode?: DeviceMode;
  hideFooter?: boolean;
  hideHeader?: boolean;
  isShowSideMenu?: boolean;
  leftSidebar?: React.ReactNode;
  onDeviceSwitcherCallback?: () => void;
  rightSidebar?: React.ReactNode;
  talkRoomId?: string;
}

const MyPageLayout: FC<Props> = ({
  afterMainContent,
  children,
  deviceMode,
  hideFooter = false,
  hideHeader = false,
  isShowSideMenu = true,
  leftSidebar,
  onDeviceSwitcherCallback,
  rightSidebar,
  talkRoomId,
}) => {
  return (
    <MyPageLayoutPresenter
      afterMainContent={afterMainContent}
      deviceMode={deviceMode}
      hideFooter={hideFooter}
      hideHeader={hideHeader}
      isShowSideMenu={isShowSideMenu}
      leftSidebar={leftSidebar}
      onDeviceSwitcherCallback={onDeviceSwitcherCallback}
      rightSidebar={rightSidebar}
      talkRoomId={talkRoomId}
    >
      {children}
    </MyPageLayoutPresenter>
  );
};

export default MyPageLayout;
