import { errorWithoutThrowInProduction } from "lib/logging/logHelper";
import { isValidUserRole, UserStatus } from "~/models/userStatus";

export function validateUserStatus(userStatus: UserStatus) {
  if (!isValidUserRole(userStatus.role)) {
    errorWithoutThrowInProduction({
      errorInfo: { userStatus },
      errorMessage: "Invalid userStatus",
    });
  }
}
