import { UrlObject } from "url";
import { Flex } from "@chakra-ui/react";
import Image from "components/atoms/Image";
import { breakPoint, colorPrimary } from "lib/constants";
import Link from "next/link";
import React, { VFC } from "react";
import styled from "styled-components";
import { oneLineContent } from "styles/CommonStyles";
import { Project } from "~/models/project";
import { pagesPath } from "mypage/src/lib/$path";

interface Props {
  logoUrl: string;
  project?: Project;
  title: string;
  titleUrl: UrlObject;
}

const LogoAndTitle: VFC<Props> = ({ logoUrl, project, title, titleUrl }) => {
  return (
    <Flex alignItems="center" justifyContent="start">
      <Link href={pagesPath.home.$url()} passHref>
        <ImageLink aria-label={title}>
          <Image
            src={logoUrl}
            width="28"
            height="28"
            alt={title}
            layout="responsive"
            ampLayout="responsive"
            objectFit="cover"
          />
        </ImageLink>
      </Link>
      <Link href={titleUrl} passHref>
        {project?.titleImagePath &&
        project?.titleImageWidth &&
        project?.titleImageHeight ? (
          <ImageTitleLink aria-label={title}>
            <Image
              src={project.titleImagePath}
              width={project.titleImageWidth}
              height={project.titleImageHeight}
              alt={title}
              layout="responsive"
              ampLayout="responsive"
              objectFit="cover"
            />
          </ImageTitleLink>
        ) : (
          <HeaderLink>{title}</HeaderLink>
        )}
      </Link>
    </Flex>
  );
};

const HeaderLink = styled.a`
  ${oneLineContent};
  color: ${colorPrimary[500]};
  cursor: pointer;
  font-size: 22px;
  font-weight: 700;
  line-height: 36px;
  margin-left: 10px;

  @media only screen and (max-width: ${breakPoint["sm"]}) {
    font-size: 22px;
  }

  @media only screen and (max-width: ${breakPoint["xs"]}) {
    font-size: 20px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const ImageLink = styled.a`
  display: block;
  width: 28px;
  height: 28px;
`;

const ImageTitleLink = styled(ImageLink)`
  margin-left: 4px;
  width: 89px;
`;

export default LogoAndTitle;
