import React, { memo, VFC } from "react";
import { Project } from "~/models/project";
import FooterPresenter from "mypage/src/components/modules/footer/FooterPresenter";

interface Props {
  asPath?: string;
  languageAlts?: string[];
  project?: Project;
}

const Footer: VFC<Props> = ({ project }) => {
  return <FooterPresenter project={project} />;
};

export default memo(Footer);
