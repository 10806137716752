const baseUrl =
  process.env.NEXT_PUBLIC_BASE_URL || process.env.NEXT_PUBLIC_VERCEL_URL;
function getDefaultLocale() {
  if (/(vn.price-rank.dev|vn-price-rank-dev)/.test(baseUrl)) {
    return "vn";
  }

  return "ja";
}

function getSupportedLocale() {
  if (/(price-rank.dev|price-rank-dev)/.test(baseUrl)) {
    return ["en", "ja", "vn"];
  }

  return ["en", "ja"];
}

module.exports = {
  defaultLocale: getDefaultLocale(),
  loader: false,
  locales: getSupportedLocale(),
  logBuild: false,
  pages: {
    "rgx:/*": ["common", "form", "mypage"],
    "rgx:/about-us/*": ["about"],
    "rgx:/bill/*": ["bill"],
    "rgx:/home/*": ["task", "waiting", "question", "dashboard"],
    "rgx:/login/*": ["login"],
    "rgx:/logout/*": ["logout"],
    "rgx:/management/*": ["management"],
    "rgx:/qa/*": ["question", "talkRoom"],
    "rgx:/specialities/*": ["specialities"],
    "rgx:/subTopics": ["topic"],
    "rgx:/talkRooms/*": ["talkRoom", "task"],
    "rgx:/tasks": ["task"],
    "rgx:/topics": ["topic"],
    "rgx:/user/*": ["user", "task"],
    "rgx:/user/registration/*": ["registration"],
  },
};
