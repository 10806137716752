export function appendFirestoreBasicColumn({
  isUpdate = false,
  obj,
}: {
  isUpdate?: boolean;
  obj: any;
}) {
  const newObj = {
    ...(obj as any),
    updatedUnixTime: Math.floor(Date.now() / 1000),
  };
  if (!isUpdate && !newObj["createdUnixTime"]) {
    newObj["createdUnixTime"] = Math.floor(Date.now() / 1000);
  }
  return newObj;
}

export function appendSystemTimeColumn({ obj }: { obj: any }) {
  const newObj = {
    ...(obj as any),
    updatedUnixTime: Math.floor(Date.now() / 1000),
  };

  if (!newObj["createdUnixTime"]) {
    newObj["createdUnixTime"] = Math.floor(Date.now() / 1000);
  }
  return newObj;
}
