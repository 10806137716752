import fetch from "isomorphic-unfetch";
import { KIKUSHIRU_API_SITE_URL } from "~/lib/constants";
import { errorWithoutThrowInProduction } from "~/lib/logging/logHelper";

export async function updateUserDefaultImageApi({
  firebaseIdToken,
  imageUrl,
  userId,
}: {
  firebaseIdToken: string;
  imageUrl: string;
  userId: string;
}): Promise<{ imageUrl: string; isSuccess: boolean }> {
  const res = await fetch(
    `${KIKUSHIRU_API_SITE_URL}/api/uploadAndResizeImage`,
    {
      body: JSON.stringify({
        height: 256,
        imageUrl,
        objectPath: `~users/${userId}/image/normal`,
        width: 256,
      }),
      headers: {
        Authorization: `Bearer ${firebaseIdToken}`,
        "Content-type": "application/json",
      },
      method: "POST",
    }
  );

  const isSuccess = res.status === 200;
  const resJson = await res.json();

  if (!isSuccess) {
    errorWithoutThrowInProduction({
      errorInfo: { resJson },
      errorMessage: "Fail uploadUserDefaultImage api call",
    });
  }

  return { imageUrl: resJson?.url, isSuccess };
}
