import { errorWithoutThrowInProduction } from "lib/logging/logHelper";
import { User } from "~/models/user";

export function validateUser(user: User) {
  if (!user?.name?.length) {
    errorWithoutThrowInProduction({
      errorInfo: { user },
      errorMessage: "Invalid user.name",
    });
  }
}

export function validateUserImageUrl(user: User) {
  if (user?.imageUrl === undefined) {
    errorWithoutThrowInProduction({
      errorInfo: { user },
      errorMessage: "Invalid user.imageUrl",
    });
  }
}
export function validateUserCoverImageUrl(user: User) {
  if (user?.coverImageUrl === undefined) {
    errorWithoutThrowInProduction({
      errorInfo: { user },
      errorMessage: "Invalid user.coverImageUrl",
    });
  }
}

export function validateUserSpecialtyIds(user: User) {
  if (!user?.specialtyIds) {
    errorWithoutThrowInProduction({
      errorInfo: { user },
      errorMessage: "Invalid user.specialtyIds",
    });
  }
}
