import {
  collection,
  getDocs,
  orderBy,
  query,
  QueryConstraint,
  where,
} from "firebase/firestore";
import {
  convertToUsers,
  userConverter,
} from "~/iterators/firebaseConverter/user";
import { firestore } from "~/iterators/firestore/firestore";
import {
  retrieveCachedUsers,
  retrieveUsersFromLatestCache,
} from "~/iterators/firestore/users/retrieveCachedUsers";
import { FIRESTORE_COLLECTION_NAME_USER, User } from "~/models/user";

export async function retrieveUsers({
  sort,
  visibleOnly = false,
  useCache = true,
  userIds,
}: {
  sort?: "updatedUnixTime" | "articlePageViewCount" | "articleCount";
  useCache?: boolean;
  userIds?: string[];
  visibleOnly?: boolean;
} = {}): Promise<User[]> {
  const queryConstraints: QueryConstraint[] = [];

  if (visibleOnly) {
    queryConstraints.push(where("visible", "==", true));
  }

  if (useCache) {
    const seedUsers = retrieveCachedUsers();
    // query with `in` condition does not allow more than 10 ids
    if (userIds && userIds?.length <= 10) {
      queryConstraints.push(where("id", "in", userIds));
    }
    let users = await retrieveUsersFromLatestCache({
      queryConstraints,
      seedUsers,
    });
    if (visibleOnly) {
      users = users?.filter((u) => u.visible);
    }
    if (userIds) {
      users = users?.filter((u) => userIds.includes(u.id));
    }
    if (sort) {
      users.sort((a, b) => (b[sort] ?? 0) - (a[sort] ?? 0));
    }
    return users;
  }
  if (sort) {
    queryConstraints.push(orderBy(sort, "desc"));
  }
  if (userIds && userIds?.length > 10) {
    return await retrieveUsersByChunk({ queryConstraints, userIds });
  }
  if (userIds?.length) {
    queryConstraints.push(where("id", "in", userIds));
  }

  const usersSnapshot = await getDocs(
    query(
      collection(firestore, FIRESTORE_COLLECTION_NAME_USER).withConverter(
        userConverter
      ),
      ...queryConstraints
    )
  );
  return convertToUsers({ usersSnapshot });
}

async function retrieveUsersByChunk({
  chunk = 10,
  queryConstraints,
  userIds,
}: {
  chunk?: number;
  queryConstraints: QueryConstraint[];
  userIds: string[];
}): Promise<User[]> {
  const queryArray = [];
  const collectionRef = collection(
    firestore,
    FIRESTORE_COLLECTION_NAME_USER
  ).withConverter(userConverter);
  // collect all query with corresponding ids
  for (let i = 0, j = userIds.length; i < j; i += chunk) {
    const ids = userIds.slice(i, i + chunk);
    queryArray.push(
      query(collectionRef, ...queryConstraints, where("id", "in", ids))
    );
  }

  // call all query
  const snapshotArr = await Promise.all(
    queryArray.map(async (query) => getDocs(query))
  );

  // convert snapshot to user
  const users: User[] = [];
  snapshotArr.forEach((usersSnapshot) => {
    users.push(...convertToUsers({ usersSnapshot }));
  });

  return users;
}
