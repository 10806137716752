import { signInWithPopup, GoogleAuthProvider, getAuth } from "firebase/auth";
import { reportError } from "../logging/logHelper";

const ignoreErrorCodes = [
  "auth/network-request-failed", // network error (such as timeout, interrupted connection or unreachable host) has occurred.
  "auth/popup-closed-by-user",
  "auth/unauthorized-domain",
];

export function loginInWithGoogle() {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider).catch((error) => {
    if (!ignoreErrorCodes.includes(error.code)) {
      reportError({
        errorInfo: { code: error.code },
        errorMessage: error.message,
      });
    }
  });
}
